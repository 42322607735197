#support .section-intro {
	@include overlay('/assets/images/hero.staff.05.1660x730.jpg', rgba(31,50,68,.8));
}

#support-form {
	.form-wrap {
		margin: 2em 0 0;
	}

	textarea {
		min-height: 225px;
	}
	.input-group {
		&.credit-card,
		&.ts,
		&.subject {
			display: none;
		}
	}

	.sales-detail {
		display: none;
		p {
			margin-top: -1em;
		}
	}

	.links {
		@extend .list-unset;
		margin-top: 4em;

		a {
			color: $offwhite;
			display: block;
			padding-bottom: 1em;
			text-decoration: none;

			&:hover {
				color: $white;
			}

			&:before {
				background-size: 100px 100px;
				content: '';
				display: block;
				@include size(100px);
				margin: 1em auto;
			}

			&.wiki:before {
				background-image: url(../images/icon.mag.svg);
			}

			&.forum:before {
				background-image: url(../images/icon.chat.svg);
			}

			&.status:before {
				background-image: url(../images/icon.wi-fi.svg);
			}

			&.contact-ts:before {
				background-image: url(../images/icon.support.svg);
			}
		}
	}

	@media (min-width: 550px) and (max-width: 845px) {
		.links li {
			display: inline-block;
			width: 45%;
		}
	}

	@media (min-width: 850px) {
		.form-wrap {
			@include outer-container;
		}

		form {
			@include span-columns(8);
			padding-right: 5%;
		}

		.links {
			border-left: 5px solid #1A2B3A;
			border-left: 5px solid rgba(0,0,0,.15);
			@include span-columns(4);
			margin-top: 0;
		}
	}
}

.wiki_margin {
	margin-bottom: 32px;
}

#support-wiki input {
	width: 70%;

	@include media(max-width 890px) {
		display: block;
		width: 100%;
		margin: 0 auto 1.2em;
	}
}

#recaptcha_area {
	margin: 0 auto;
}

/* Return url for off-site form submissions */

.contact-return-text {
	@include span-columns(8);
	padding-right: 5%;
	display: none;
}

body.contact-return {
	#support-form form {display: none}
	.contact-return-text {display: block;}
}
