@mixin header-nav__link {
	@include button-unset;
	color: $white;
	display: block;
	@include font-size( 14 );
	font-weight: bold;
	line-height: 1;
	margin: 0 auto;
	padding: 1em 1.2em;
	position: relative;
	text-decoration: none;


		display: inline-block;
		vertical-align: middle;
		width: auto;

	&:hover {
		color: $brightblue;
	}
}

.header-nav {
	@include mq-min-width($header-nav--desktop) {
		display: inline-block;
	}

	ul {
		@extend .list-unset;
		display: inline-block;
	}

	li {
		@include mq-min-width($header-nav--desktop) {
			display: inline-block;
		}
	}

	.js-toggle-dropdown {
		@include mq-max-width($header-nav--desktop - 1) {
			display: none;
		}
	}

	.list-dropdown {
		top: 120%;
		left: -.5em;
		right: auto;
	}

	@include mq-max-width($header-nav--desktop - 1) {
		.list-dropdown {
			// Unset list-dropdown basically
			position: static;
			display: block;
			opacity: 1;
			visibility: visible;
			background: none;
			border: none;
			padding: 0;
			transform: none;

			&:before,
			&:after {
				display: none;
			}
		}

		.list-dropdown__link {
			// Unset style
			@include header-nav__link;
		}
	}
}

.header-nav__link {
	@include header-nav__link;

	.list-dropdown__wrap &:after {
		content: '';
		display: inline-block;
		width: 0;
		height: 0;
		border: .3em solid transparent;
		border-bottom-width: .14em;
		border-top-color: currentColor;
		vertical-align: middle;
		margin-left: .4em;
	}
}
