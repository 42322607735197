/*

Price Block

e.g /cloud/computing. Use as a mixin with the amount of columns and which if any you'd like to highlight as paramaters:

.your-price-block {
	@include price-block(5, 3);
}

Markup:
<div class="clearfix">
	<div class="price-block">
		<div class="price-block__header">
			<h3 class="price-block__heading">$25<span class="per-month">per month</span></h3>
		</div>
		<div class="price-block__details">
			<ul>
				<li><span>Memory: </span>4GB</li>
				<li><span>Instances: </span>2 <a href="javascript:void" data-toggle="modal" data-target="#instanceModal" class="instance-modal-trigger">(?)</a></li>
				<li><span>vCPUs: </span>2</li>
				<li><span>Block storage: </span>200GB</li>
				<li><span>Public IPs </span>1</li>
			</ul>
		</div>
		<div class="price-block__footer">
				<a href="//signup.dreamhost.com/compute/?service_type=1374" class="btn-signup">Sign up</a>
		</div>
	</div>
	<div class="price-block">
		<div class="price-block__header">
			<h3 class="price-block__heading">$50<span class="per-month">per month</span></h3>
		</div>
		<div class="price-block__details">
			<ul>
				<li><span>Memory: </span>4GB</li>
				<li><span>Instances: </span>2 <a href="javascript:void" data-toggle="modal" data-target="#instanceModal" class="instance-modal-trigger">(?)</a></li>
				<li><span>vCPUs: </span>2</li>
				<li><span>Block storage: </span>200GB</li>
				<li><span>Public IPs </span>1</li>
			</ul>
		</div>
		<div class="price-block__footer">
				<a href="//signup.dreamhost.com/compute/?service_type=1374" class="btn-signup">Sign up</a>
		</div>
	</div>
</div>

Styleguide Things.price-block

*/

@mixin price-block($columns, $highlight) {
	$small: 600px;
	$large: 900px;

	-webkit-font-smoothing: antialiased;
	text-align: center;

	@media screen and (min-width: $small) {
		width: 50% - 1%;
		float: left;
	}

	@media screen and (min-width: $large) {
		width: (100% / $columns) - 1%;
	}

	&:not(:last-child) {
		margin-right: 1%;
		margin-bottom: 1rem;
	}

	&:nth-child(#{$highlight}) {
		@media screen and (min-width: $large) {
			transform: scale(1.2);
			opacity: 1;
			position: relative;
			z-index: 10;
			background: #fff;
		}
	}

	&__details {
		padding: 1em 0 0;

		ul {
			@include list-unset;
			font-size: .85em;
			margin: 0;
		}
		li {
			margin: .25em 1em;
			padding: .25em 0;
			&:last-child {margin-bottom: 0;}
		}
		span {
			font-weight: bold;
		}

	}

	&__heading {
		color: $darkBlue;
		padding: 1em 0;
		font-weight: 400;
		margin: 0 2em;
		border-bottom: 5px solid $lightBlue;
		span {
			display: block;
			font-style: italic;
			font-size: 16px;
			border-bottom: 0;
		}
	}

	&__footer {
		padding: 1em;
	}

}

.price-block {
	@include price-block(2, 0);
}
