.section-button-row {
	@include font-size(12);
	position: relative;
	z-index: 199;

	ul {
		@include list-unset;
	}
}

.button-row--button {
	background-color: $white;
	background-image: -webkit-gradient(linear, 130deg, right bottom, from($white), to(#f2f2f2));
	background-image: -webkit-linear-gradient(130deg, $white, #f2f2f2);
	background-image: -moz-linear-gradient(130deg, $white, #f2f2f2);
	background-image: -o-linear-gradient(130deg, $white, #f2f2f2);
	background-image: -ms-linear-gradient(130deg, $white, #f2f2f2);
	background-image: linear-gradient(130deg, $white, #f2f2f2);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='$white', EndColorStr='#f2f2f2');

	border-bottom: 1px solid #E6E6E6;
	color: $darkBlue;
	display: block;
	padding: 2em;
	text-align: center;
	text-decoration: none;
	overflow: hidden;

	&:hover {
		background: $white;

		.button-row--content {
			@include transform( scale(1.05) );
		}
	}
}

.button-row--content {
	transition: all .1s ease-in-out;
	position: relative;
}

.button-row--headline {
	@extend .h3;
	font-weight: normal;

	img + & {
		margin-top: .5em;
	}
}

.button-row--dhc {
	img {
		position: relative;
		bottom: -2em;
		margin-top: -1em;
	}

	.button-row--content {
		vertical-align: bottom;
	}

	@media (max-width: 779px) {
		padding-bottom: 0;
	}
}


@media (min-width: 780px) {
	.section-button-row {
		ul {
			display: table;
			width: 100%;
		}

		li {
			display: table-cell;
			vertical-align: top;
			width: 25%;

			+ li {
				border-left: 1px solid #E6E6E6;
			}
		}

		.button-row--button {
			border: none;
			display: table;
			height: 240px;
			width: 100%;
		}

		.button-row--content {
			display: table-cell;
			vertical-align: middle;
		}

		.button-row--dhc {
			.button-row--content {
				padding-bottom: 110px;
			}

			img {
				margin-top: 0;
				position: absolute;
				left: 0;
				bottom: -3em;
			}
		}
	}
}
