.list-awards {
    display: table;
    margin: 2em auto 0;

    li {
        display: table-cell;
        padding: 0 5%;
        text-align: center;
        vertical-align: middle;
        width: 33%;
    }
}
