/* Thanksgiving Hero */
.section-hero--thanksgiving:before{ background-image: url(../images/thanksgiving.1660x730.jpg); }

/* Promo page disclaimer */

.promo-disclaimer {
	background-color: rgba(0,0,0,.35);
	color: $bluegrey;
	display: inline-block;
	font-size: .7em;
	text-align: center;
	margin-top: 60px;
	padding: 17px 30px;
	max-width: 600px;
	border-radius: 40px;
	}

.promo-disclaimer--light{
	background-color: $white;
}

.promo {
	@include clearfix();
	padding: 0 5.25em;
	color: #fff;
	@media screen and (max-width: 600px) {
		padding: 0 2em;
	}

	&__intro {
		font-size: 21px;
		margin-bottom: 2em;
	}

	&__heading {
		font-weight: 500;
	}

	&__left {
		@media screen and (min-width: 600px){
			max-width: 65%;
			float: left;
		}
	}

	&__right {
		text-align: center;
		@media screen and (min-width: 600px){
			max-width: 25%;
			float: right;
		}

		img {
			width: 250px;
			display: block;
			margin-top: -25px;
		}
		p {
			@include font-size(22);
			font-family: $font-heading;
			font-weight: bold;
			line-height: 1.2;
		}
	}

	@media screen and (min-width: 600px) {
		ul {
			max-width: 95%;
			margin: 0 auto;
		}
	}

	h2 {font-weight: bold;}

	h2+p {margin-top: 0;}

	li {margin-bottom: 1.25em;}

	.list-checkmarks li:before {
		font-size: 32px;
	}

	.promo-disclaimer {
		max-width: 80%;
		display: block;
		margin: 6em auto 0;
	}
}

#filezilla .section-intro {
	@include overlay('/assets/images/logo.filezilla.svg', rgba(74.9,0,0,.8));
	background-size: contain;
	background-position: center 150px;
}

.filezilla-disclaimer {
	@include font-size(14);
	position: relative;
	top: 2em;
}
