.section-offscreen {
    overflow: hidden;
    text-align: center;
}

.section-offscreen__graphic,
.section-offscreen__copy {
    margin: 0 auto;

    @include mq-min-width(690px) {
        display: inline-block;
        position: relative;
        vertical-align: middle;
    }
}

.section-offscreen__copy {
    text-align: left;
    max-width: 350px;
}

.section-offscreen__graphic {
    width: 100%;
    height: auto;
    right: 100px;
}

.section-offscreen__copy + .section-offscreen__graphic {
    margin-top: 60px;
    right: 0;
    left: 100px;
}

.section-offscreen__copy + .section-offscreen__graphic,
.section-offscreen__graphic + .section-offscreen__copy {
    margin-top: 60px;

    @include mq-min-width(690px) {
        margin-top: 0;
    }
}

.control-panel-benefits .section-offscreen__graphic {
    // Let it flow full-width smaller than 690px

    @include mq-min-width(690px) {
        width: 265px;
    }

    @include mq-min-width(835px) {
        width: 397px;
    }

    @include mq-min-width(982px) {
        width: 529px;

    }
}
