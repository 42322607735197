#tld-hero:before {
	display: block;
	content: '';
	background: rgba(31, 50, 68, .8);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

#tld-hero {
	position: relative;
	background-image: url(/assets/images/photo.unsplash.computer.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	z-index: 1;
	color: #FFFFFF;
	overflow-x: hidden;
}

.tld-intro {
	position: relative;
	max-width: 700px;
	padding: 4em 1em;
	h1, .h1 {
		@include font-size(32);
		margin: 0;
		margin-top: 0.5em;
	}
	.p {
		@include font-size(16);
		font-weight: normal;
		margin: 0;
		margin-top: 0.5em;
	}
	img {
		max-width: 50%;
		@include mq-min-width(650px) {
			width: 100%;
			max-width: 400px;
		}
	}
}

.tld-callout {
	@extend .tag;
	@include font-size(16);
	display: inline-block;
	margin: 1em auto 0;
}

.tld-info {
	max-width: 960px;
	margin: 0 auto;
	li {
		margin-bottom: 2em;
	}
	.list-default {
		margin: 0 auto;
		columns: 2;
		@include mq-min-width(650px) {
			columns: 3;
		}
		li {
			padding-left: 1em;
			margin-bottom: 0;
		}
		li:before {
			position: initial;
		}
	}
}

.xyz-logo {
	margin-bottom: -20px;
}
