/*

Box

It's just a nice box style. Preferably you would extend this to your class using sass, but you could also just use the class box. See Product Feature Box for this in action.

Markup:
<div class="box">Hi, I'm in a box.</div>

Styleguide Things.box

*/

.box {
	padding: 3em;
	background: #eee;
	&:nth-child(2) {background: #ddd;}
	.section-dark & {background: rgba(255,255,255,.05);}
}

.box--white {
	background: $white;
	&:nth-child(2) {background: $white;}
}