.url_404,
.url_404 body {
	height: 100%;
}

#page-404 {
	background: url(../images/404.gif) no-repeat right center;
	background-size: cover;
	color: $white;
	height: 100%;
	width: 100%;

	display: table;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0,0,0,.5);

	.wrap {
		display: table-cell;
		vertical-align: middle;
	}

	h1 {
		color: $white;
	}

	.error-code {
		position: absolute;
		bottom: 10px;
		right: 10px;
		font-size: 14px;
		color: #525252;
	}
}
