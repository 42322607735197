.base-price {
	@include font-size(50);
}

.price-circle {
	background: $lightBlue;
	border-radius: 2.5em;
	color: $white;
	display: inline-block;
	@include font-size(38);
	line-height: 1.2;
	margin: 0 .75em;
	padding-top: 1.7em;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0,0,0,.45);
	vertical-align: middle;

	@include size(5em);

	small {
		font-size: .8em;
	}

	.sub {
		color: $darkBlue;
		display: block;
		font-size: .37em;
		padding: 0 5%;
		text-shadow: none;
		text-transform: uppercase;
	}
}

.price-circle-popular {
	background: $peach;
}
