/*

Tags

Description.

Colors (based off _buttons.scss)
.tag            - Blue
.tag--dark      - Dark blue
.tag--red       - Red
.tag--orange    - Orange
.tag--yellow    - Yellow
.tag--disabled  - Grey

Markup:
<span class="tag {{modifier_class}}">Tag</span>

Styleguide components.tags

*/

.tag {
	border-radius: 3px;
	background: $brightblue;
	color: $white;
	cursor: default;
	@include font-size(14);
	padding: .28em .43em;
	margin: 0 .4em;
}

.tag--dark { background: $dullblue; }
.tag--red { background: $red; }
.tag--orange { background: $orange; }
.tag--yellow { background: $yellow; color: white(14%); }
.tag--disabled { background: white(95%); color: white(32%); }
