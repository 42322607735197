@mixin link-color($color: $white, $hover: $brightblue) {
	color: $color;

	&:hover {
		color: $hover;
	}
}

@mixin dark-links {
    @include link-color( $lightBlue, $darkBlue );
}

@mixin light-links {
    @include link-color( $white, $brightblue );
}
