/*

Grid Builder

We can build custom one-off grids. Grid frameworks are great for quick things but aren't really adaptable if the design goes off-grid a bit.\
This will create a fluid grid inside a centered container with a max-width.
If you need a quick grid for prototyping, see components/_grid.scss

$row-width: The max-width the row should be in pixels
$columns: The number of columns in this row
$column-width: The width of a single column in pixels

Markup:
.a-container-that-is-the-wrapper-for-a-grid {
	@include build-grid(768px, 3, 220px);
}

*/

@mixin build-grid($row-width, $columns, $column-width) {
	$column-percentage: ($column-width / $row-width) * 100%;

	@include max-width($row-width);
	@include clearfix;

	.column {
		float: left;
		width: $column-percentage;

		+ .column {
			margin-left: ((100% - $columns * $column-percentage) / ($columns - 1));
		}
	}
}
