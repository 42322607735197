.section-article {
	padding-top: 1px;

	.section-wrap {
		background: $white;
		border: 1px solid rgba(0,0,0,.1);
		padding: 2em;
		margin: 0 auto;
		max-width: 45em;

		@media (min-width: 45em) {
			border-radius: 3px;
		}

		h4 {
			font-size: 1.25em;
			margin-top: 1.5em;

			& + p {
				margin-top: 0;
			}
		}

		li {
			margin-bottom: 1em;
		}
	}
}
