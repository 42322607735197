.simple-domain-search {
	position: relative;
	z-index: 1;
	.section__headline {
		text-align: center;
		margin-bottom: 1em;
	}
}

.simple-domain-search:before{
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	opacity: .7;
	background-image: url("/assets/images/bg.tlds.png");
	background-position: top center;
	background-size: 80% auto;
}

.tld-section-header {
	margin: 0;
	padding: 0.5em;
	background-color: lighten($darkBlue, 5);
	text-align: center;
	margin-top: 3em;
}

.domain-text {
	display: block;
	text-align: center;
	@media (min-width: 640px) {
		display: table-cell;
		text-align: left;
	}
}

.btn-signup-domain {
	display: block;
	text-align: center;
	margin: 1rem auto;
	width: 175px;
	@include font-size(14);
	@media (min-width: 640px) {
		display: table-cell;
	}
}

.result-row {
	color: $white;
	padding: 0.75em;
	&:not(:last-child) {
		border-bottom: 1px solid lighten($darkBlue, 5);
	}
}

.domain-search-btn:disabled {
	background-color: lighten($grey, 30%);
}

.exact-result {
	text-align: center;
	padding: 1em;
	.btn-signup.btn-signup-domain {
		display: block;
		margin-top: 1em;
	}
	p {
		margin: 0.5em 0 0;
	}
}

.tlds-section {
	box-shadow: 0 0 3px lighten($darkBlue, 5);
}

.sale-tld {
	.btn-signup:before {
		@extend .tag;
		content: 'Sale';
		background-color: $peach;
		position: absolute;
		margin-left: -50px;
		margin-top: -2px;
	}
}

.tlds-special-offer-disclaimer {
	margin: 1em 0;
	text-align: center;
	font-size: 0.85em;
}

.tlds-section.slidein {
	animation-duration: 500ms;
	animation-name: slidein;
	opacity: 1;
}

@keyframes slidein {
	from {
		opacity: 0;
		width: 120%;
		margin-left: -30%;
	}

	to {
		opacity: 1;
		width: 100%;
		margin-left: 0;
	}
}
