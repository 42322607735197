@mixin overlay($url, $color){
  position: relative;
  background-image: url($url);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  z-index: 1;
  color: #fff;
  overflow-x: hidden;

  &:before {
    display: block;
    content: '';
    background: $color;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .section-wrap {
    position: relative;
    z-index: 2;
  }

}
