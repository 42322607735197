#were-green {
	h3 {
		font-size: 2.2rem;
		padding-top: .3.5em;
	}

	.were-green-url {
		color: $teal;
		font-size: 1.5rem;
		margin-bottom: -1em;
		margin-top: 0.8em;
		text-decoration: none;
	}
}

#were-green-feed {
	margin-top: 5em;

	.were-green-article {
		text-align: left;
		margin: 0 0 4.7em;

		a:link,
		a:visited {
			text-decoration: none;
			color: $teal;
		}

		a:hover,
		a:active {
			text-decoration: underline;
		}
	}

	.were-green-thumb {
		float: left;
		margin: 0 2.4em 1em 0;
	}

	.excerpt {
		font-size: 1.7rem;
	}
}
