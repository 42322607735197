.hosting-comparison-chart {
	@include smooth-type;

	h2 {
		color: #fff;

		+ p {
			@include max-width(40em);
			@extend p.spacing;
			color: #fff;
		    font-size: 1em;
		    a {
		    	color: #fff;
		    }
		}
	}

	a:hover {
		color: #fff;
	}

}

.comparison-toggles {
	@include list-unset;
	margin: 2em 0;

	li {
		display: inline;
		&:not(:last-child) {margin-right: 20px;}
	}

	a {
		color: #fff;
		font-weight: bold;
		text-decoration: none;
		padding-bottom: .25em;
		transition: .3s;
		border-bottom: 0px solid $darkBlue;

		&:hover,
		&.active {
			border-bottom: 5px solid $darkBlue;
		}

		&.active {
			font-weight: bold;
		}
	}
}

#comparisonChart-wrapper {
	max-width: 750px;
	margin: 0 auto;
}

@media screen and (max-width: 650px) {
	.comparison-toggles,
	#comparisonChart-wrapper {
		display: none;
	}
}
