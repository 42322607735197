.legal-docs {
	@extend .list-unset;
	font-size: 1.15em;
	padding: 1em 1em 0 1em;

	a {
		color: $grey;
		text-decoration: none;

		&:hover {
			color: $lightBlue;
		}
	}
}

#tld-list {
	@include font-size(14);
	th {
		text-align: left;
	}
}
