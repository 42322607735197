.article-list {
    font-size: 1.6rem;
    margin-top: 4em;
    text-align: left;
}

.article-list__article {
    margin-top: 3em;
    padding-bottom: 3em;
    border-bottom: 1px solid #132435;

    @include mq-min-width(650px) {
        display: table;
        margin-bottom: 0;
        width: 100%;
    }

    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }
}

.article-list__thumb {
    display: none;

    @include mq-min-width(650px) {
        display: table-cell;
        width: 175px;
    }
}

.article-list__text {
    @include mq-min-width(650px) {
        display: table-cell;
        vertical-align: top;
        padding-left: 2.4em;
    }
}

.article-list__title {
    font-size: 1.375em;

    a {
        text-decoration: none;
    }
}

.article-list__date {
    color: $teal;
    font-size: .9375em;
    margin-bottom: -1em;
    margin-top: 0.8em;
}

.article-list__excerpt {
    font-size: 1.0625em;
}
