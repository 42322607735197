.promo-banner {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 201;

	background: $lightBlue;
	color: $white;
	font-size: .65em;
	text-align: center;

	.btn {
		margin-left: 1em;
	}
}

.has-promo {
	// Add 3em to to the top to make room for the promo banner
	padding-top: 6.7em;

	#page-header {
		top: 3em;
	}

	.is-sticky .sticky-nav {
		margin-top: 3em;
	}
}

.is-closed {
	position: absolute;
	top: -6.7em;
	@include transition(all, 0.2s, ease-in-out);
}

// Close the promo!
.promo-close {
	position: relative;
	float:right;
	margin: 0 10px;
	svg {
		width:37px;
		height:37px;
		padding:10px;
		cursor: pointer;
		&:hover {
			polygon {
				fill: #273f56;
				@include transition(all, 0.2s, ease-in-out);
			}
		}
	}
}

// Quick responsive promo image in the hero.
.promo-image-container {
	position:relative;
	display: block;
	margin: 0 auto;
	z-index:99;
}
.open-source-promo-image {
	width: 316px;
	height: 408px;
	margin-top: 5px;
	background: url(../images/open.source.logo.png) center center no-repeat;
	background-size: auto 100%;
}

@media (min-width:785px) {
	.promo-image-container {
		position: absolute;
		left: -450px;
		@include shift(6);
	}
}
