/*

Section

Every section of a page on the DreamHost website is inside of a section tag that determines its width and colour. All of these modifier classes can be mixed and matched.

.section-dark         - A dark background
.section-centered		- Yeah, centered!

Markup:
<section class="section {{modifier_class}}">
	<div class="section-wrap">
		Yeah! Now I'm padded out nicely and can have content added to me! Maybe I'm even dark or centered.
	</div>
</section>

Styleguide Section.1

*/

.section-wrap {
	@include max-width(1200px);
	padding: 8em 5%;

	footer & {
		padding: 4em 5%;
	}
}

.section-centered {
	text-align: center;
}

.section--text-large {
	font-size: $font-main;
}

.section--bottom-border {
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 10%;
		border-top: 1px solid black(14%);
		width: 80%;

		@media (min-width:990px) {
			width: 800px;
			left: 50%;
			margin-left: -400px;
		}
	}
}

.section--highlight {
	background: $subtleblue;
}

.section--bright {
	background: $lightBlue;
	color: #fff;
}

.section--dark {
	background: $dullblue;
	color: #fff;
}

.section__subheadline {
	max-width: 800px;

	.section-center &,
	&.u-text-center {
		@include max-width(800px);
	}
}

.section--short {
	.section-wrap {
		padding-top: 4em;
		padding-bottom: 4em;
	}
}
