.lets-encrypt-faq {
	p, ul {margin-bottom: 3em;}
}

.lets-encrypt-info ul {
	max-width: 700px;
	margin: 0 auto;
}

.lets-encrypt-intro {
	position: relative;
}

.lets-encrypt-lock {
	position: absolute;
	bottom: 0;
	right: 0;
	opacity: .1;
}

.lets-encrypt-comparison .box {
	@extend .box;
	margin-bottom: 2em;
	@media screen and (min-width: 600px) {
		@include span-columns(6);
		@include omega(2n);
		margin-bottom: 2.35765%; // neat spacing
	}
	p:last-child {
		margin-bottom: 0;
	}
	background-color: rgba(255, 255, 255, 0.05);
}
