
#dhc-benefits {
	.list-table {
		margin: 12em auto 3em;
	}

	@media (min-width: 900px) {
		.pricing {
			@include outer-container;
			margin-bottom: 0;
		}

		.price-circle {
			margin: 0px 0px -40px -2.5em;
		}

		.active-circle {
			font-size: 150%;
			margin: 0px 0px -40px -2.4em;
			padding-top: 1.7em;
		}

		.inactive-circle {
			background: none repeat scroll 0% 0% #939494;
			font-size: 110%;
		}

		.list-table {
			@include span-columns(4);
			margin-top: 10em;
		}
	}

	.dhc-prices table thead tr th:last-child, .dhc-prices table tbody tr td:last-child {
		max-width: 100px;
	}

	.dhc-prices .btn-signup {
		margin-top: 2em;
	}
}

.white-icon{
	fill: $white;
	width: 50px;
	margin-top: 2em;
	margin-bottom: 1em;
}

#dhc-free-trial {
	fieldset {
		border: none;
	}

	@media (min-width: 750px) {
		fieldset {
			@include outer-container;
			margin-top: 3em;
		}

		.input-group {
			@include span-columns(6);
		}
	}
}

.dhc-sidebar {
	margin-top: 7em;
}
