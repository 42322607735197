.domain-privacy-hero {
	@include overlay('/assets/images/hero.privacy.1660x730.jpg', rgba(31, 50, 68, .9));

	h1 {
		font-size: 1.2em;
		margin-bottom: 1em;
	}
	h3 {
		font-size: 1.3em;
		margin-bottom: 2em;
		span {
			color: #FFBA00;
		}
	}
	.info-left, .info-right {
		padding: 0;
		margin: 0;
		ul {
			text-align: left;
		}
	}
	@media screen and (min-width: 960px) {
		.info-columns {
			.info-left {
				text-align: right;
				width: 35%;
				padding: 0 4rem;
				border-right: 1px solid #FFFFFF;
			}
			.info-right {
				text-align: left;
				width: 65%;
				margin: 0;
			}
			ul {
				margin: 0;
				font-size: 1.05em;
				padding: 0 3rem 0 6rem;
			}
			h3 {
				margin-bottom: 0;
			}
		}
	}
	.section-wrap {
		padding: 4em 0;
	}
}

.info-columns {
	width: 100%;
	display: table;
	max-width: 1000px;
	margin: 0 auto;
	font-size: 16px;
	.info-left, .info-right {
		display: block;
		width: 100%;
		padding: 0 2rem;
		@media screen and (min-width: 960px) {
			display: table-cell;
			width: 50%;
			vertical-align: middle;
		}
	}
}

.domain-privacy-info {
	.info-right {
		@media screen and (min-width: 960px) {
			text-align: left;
			padding-left: 4rem;
		}
	}
}

.divider {
	border-bottom: 1px solid #d8d8d8;
	max-width: 960px;
	display: block;
	margin: 0 auto;
}
