.section-cta {
	background: darken(#f9f9f9, 5%);
	padding: 2em 0;
	.section-dark & {
		background: darken($darkBlue, 5%);
	}

	h2 {
		@include font-size(21);
		margin-bottom: 1em;
	}
}
