/*

Table

Looks like a table. For plans.

Markup:
<ul class="list-table">
	<li>Plan name</li>
	<li>1GB RAM</li>
	<li>1GB SSD Storage</li>
	<li>Unlimited Bandwidth</li>
	<li>Unlimited Domains</li>
	<li><a href="#" class="btn-signup">Buy this thing</a></li>
</ul>

Styleguide Lists.table

*/

.list-table {
	@include list-unset;
	background: $white;
	@include font-size(20);
	@include outer-glow;
	border-radius: 2px;
	margin: 2em 0 4em;

	li {
		color: $darkBlue;
		padding: 1.2em;
		position: relative;

		&:nth-child(odd) {
			background: $lightgrey;
		}

		&:first-child {
			background: $darkBlue;
			color: $white;
			font-size: 1.5em;
			font-weight: normal;
			line-height: 1;
			@include smooth-type;
			padding: .7em;

			> small {
				color: $lightergrey;
				font-size: .5em;
				line-height: 1.4;
				text-transform: uppercase;
			}
		}
	}

	.price-circle {
		position: absolute;
		bottom: 85%;
		left: 50%;
		margin: 0 0 0 -2.5em;
	}
}
