/*

Product Grid

Mostly used in section-intro, e.g on /hosting.
If used in a section that is not section-intro or section-dark it will appear with a grey background.
Add a ribbon to a product with .ribbon-popular.
Add a learn more link to the right of your sign up button with .product-footer__learn-more.

Markup:
<div class="section-intro">
	<div class="section-wrap">
		<ul class="product-grid">
			<li class="product product-cloud">
			<div class="ribbon-popular">THE BEST THING</div>
				<header class="product-header">
					<h3>DreamCompute</h3>
					<p>Next-Gen Public Cloud Computing</p>
				</header>
				<p class="product-description">Open source cloud servers built to power your dev environments, web &amp; mobile apps, ecommerce sites and much more.</p>
				<ul class="product-features">
					<li>Full Root Access</li>
					<li>Tenant Network Isolation</li>
					<li>OpenStack API Accessible</li>
					<li>Choose Your Own Linux Distro</li>
				</ul>
				<p class="product-price">All this starting at $10/mo!</p>
				<a href="/cloud/computing/" class="btn btn-signup">Learn More about DreamCompute</a>
			</li>
			<li class="product product-cloud">
				<header class="product-header">
					<h3>DreamObjects</h3>
					<p>Developer-Friendly Object Storage</p>
				</header>
				<p class="product-description">Securely store all of your personal backups, website and blog files, videos, music, podcasts and much more.</p>
				<ul class="product-features">
					<li>Fully Scalable</li>
					<li>Unlimited Inbound Transfer</li>
					<li>AWS S3 &amp; OpenStack Swift API-Compatible</li>
					<li>No per-Request Fees</li>
				</ul>
				<p class="product-price">Storage starts at just 2.5&cent;/GB monthly</p>
				<a href="/cloud/storage/" class="btn btn-signup">Learn More about DreamObjects</a>
			</li>
		</ul>
	</div>
</div>

Styleguide Things.product-grid

*/

.product-grid {
	@extend .list-unset;
	margin-top: 2em;
	font-size: 16px;

	.product {
		text-align: center;
		margin: 4em 0;
		position: relative;
		padding: 2em;
		background: $lightgrey;
		.section-dark & {
			background: rgba(255,255,255,.1);
		}

	}

	.product-header,
	.product-description,
	.product-features,
	.product-price,
	.product-footer {
		padding: 0 1em;
	}

	.product-header {

		h3, h2 {
			font-size: 2.5rem;
			font-weight: 100;
			margin-bottom: 1.5em;
		}

		p {
			margin: 0;
			font-weight: bold;
		}

	}

	.product-description {
		@include font-size(18);
		font-weight: 500;
		margin: 0;
		padding: 0 2em;
	}

	.product-features {
		@extend .list-unset;
		padding: 0;
		background: #E7E7E7;
		background: rgba(0,0,0,.07);
		margin: 2em 3em;
		li {
			margin: 0;
			padding: .5em;
			&:not(:last-child){border-bottom: 1px solid rgba(255, 255, 255, 0.1)};
		}

	}

	.product-price {
		small {
			display: block;
		}
	}

	.product-wordpress .product-header:before {
		background-image: url(../images/logo.wp.silhouette.svg);

		.no-svg & {
			background-image: url(../images/logo.wp.silhouette.64x64.png);
		}
	}
}

@media (min-width: 800px) {
	.product-grid {
		font-size: 0;
		margin: 0 -5%;

		.product {
			@include font-size(19);

			margin: 4em 5% 0 5%;
			display: inline-block;
			vertical-align: top;
			width: 40%;
		}
	}
}

.product-footer__learn-more {
	margin-left: 15px;
	font-size: .85em;
	margin-top: .75em;
	.section-dark & {
		color: #fff;
	}
}
