*,
*:before,
*:after {
	box-sizing: border-box;
}

/*

Typography

Because words are important.

Markup:
<h1>Heading one</h1>
<h2>Heading two</h2>
<h3>Heading three</h3>
<h4>Heading four</h4>
<h5>Heading five</h5>
<p>Paragraph text.</p>


Styleguide Base.typography

*/

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	font-family: $font-heading;
	font-weight: normal;
	line-height: 1.2;
	margin: 0;

	+ p,
	+ .p {
		margin-top: .5em;
	}

	+ .p {
		margin-bottom: 1em;
	}
}

h1, .h1 {
	// 2.441em - Major Third (1.25) Scale
	@include font-size(39);
}

h2, .h2 {
	// 1.953em - Major Third (1.25) Scale
	@include font-size(31);
}

h3, .h3 {
	// 1.563em - Major Third (1.25) Scale
	@include font-size(25);
}

h4, .h4 {
	// 1.25em - Major Third (1.25) Scale
	@include font-size(20);
}

h5, .h5 {
	// 1em - Major Third (1.25) Scale
	@include font-size(16);
	font-family: $font-body;
	font-weight: bold;
}

.p {
	font: #{$font-base} / 1.5 $font-body;
	> iframe {
		display: none;
	}
}

.inline {
	display: inline;
}

a {
	@include transition(color .1s ease-in-out);
	@include dark-links;
}

button {
	outline: none;
}

body {
	@extend .section-light;
	@include font-feature-settings("liga");
	@include smooth-type();
	background: $dullblue;
	font: #{$font-base} / 1.5 $font-body;
	> iframe {
		display: none;
	}
}

.main-content {
	background: #f9f9f9;
}

img {
	max-width: 100%;
	height: auto;
}

p.spacing,
ul.spacing,
a.spacing,
div.spacing,
h3.spacing,
h4.spacing {margin-bottom: 6rem}

h2.spacing {margin-bottom: 8rem}

.no-margin {
	margin: 0 !important;
}

.for-screenreader {
	// http://webaim.org/techniques/css/invisiblecontent/
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
