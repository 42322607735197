.tlds-page {
	background-color: $white;
	color: $darkBlue;
	position: relative;
	h1 {
		color: $darkBlue;
		margin-bottom: 0;
	}
}

.tlds-page-container {
	max-width: 600px;
	margin: 0 auto;
	height: 100%;
}

.tlds-table-header {
	border-radius: 5px 5px 0 0;
	width: 100%;
	display: table;
	padding: 1em 0.5em;
	background-color: $darkBlue;
	color: $white;
	@include font-size(15);
	@media (min-width: 640px) {
		@include font-size(16);
		padding: 1em;
	}
	&-name {
		display: table-cell;
		width: 30%;
		font-weight: normal;
		text-align: left;
	}
	&-pricing {
		display: table;
		width: 100%;
		&-register, &-renew {
			display: table-cell;
			text-align: right;
			width: 46%;
		}
		&-renew {
			padding-right: 1em;
		}
	}
}

.tlds-table-spinner {
	@include spinner(30px);
	margin: 4em auto;
}

.tlds-table-results {
	width: 100%;
}

.tlds-table-container {
	display: block;
	overflow: auto;
	border: 1px solid lighten($grey, 40%);
	border-radius: 0 0 5px 5px;
	height: 300px;
	@include font-size(15);
	@media (min-width: 640px) {
		height: 400px;
		@include font-size(16);
	}
}

.tlds-table-row {
	@include font-size(15);
	@media (min-width: 640px) {
		@include font-size(16);
	}
	&:nth-child(even) {
		background-color: lighten($grey, 55%);
	}
}

.tlds-table-row-info {
	display: table;
	width: 100%;
	padding: 1em 0.5em;
	@media (min-width: 640px) {
		padding: 1em;
	}
	&:hover {
		background-color: lighten($lightBlue, 55%);
	}
	&-tld {
		display: table-cell;
		text-align: left;
		width: 30%;
		overflow: hidden;
		.sale-tag {
			background-color: $peach;
			color: $white;
			border-radius: 3px;
			padding: 0 4px;
			margin-left: 0.5em;
		}
	}
	&-pricing {
		display: table;
		width: 100%;
		&-register, &-renew {
			display: table-cell;
			width: 50%;
			text-align: right;
		}
	}
	&.form-toggle {
		display: none;
	}
}

.tlds-table-row-form {
	display: none;
	padding: 1em;
	width: 100%;
	&.form-toggle {
		display: table;
	}
}

.tld-search-field {
	margin: 0;
	padding: 0.25em 0.5em;
	border: 1px solid lighten($grey, 50%);
	display: table-cell;
	width: 100%;
}
.tld-search-btns {
	text-align: right;
	display: table-cell;
	margin-top: 0;
	width: 40%;
	@media (min-width: 640px) {
		width: 15%;
	}
}
.tld-search-btn {
	margin-left: 0.5em;
	display: table-row-group;
	height: 30px;
	width: 30px;
	background: none;
	border: none;
	vertical-align: middle;
	background-repeat: no-repeat;
	opacity: 0.8;
	&:hover {
		opacity: 1;
	}
	&.search-cancel {
		background-image: url(/assets/images/icon.basic.close.svg);
	}
	&.search-go {
		background-image: url(/assets/images/icon.basic.search.svg);
	}
}

#tlds-search-box {
	@include font-size(16);
	border: 1px solid lighten($grey, 40%);
	padding: 0.75em 1em;
	width: 100%;
	max-width: 600px;
	box-sizing: border-box;
}

.sort-btns {
	display: block;
	padding: 0.5em 0;
	@media (min-width: 640px) {
		padding: 1em 0;
	}
}

.sort-btn {
	@include font-size(16);
	padding: 0 0.5em;
	background: none;
	border: 1px solid lighten($grey, 50%);
	border-radius: 3px;
	display: inline-block;
	margin: 0.25em 0.25em 0.25em 0;
	&:active {
		transform: translate(0, 1px);
	}
	&:hover {
		background-color: lighten($lightBlue, 55%);
	}
	&.current {
		background-color: $lightBlue;
		border: 1px solid $lightBlue;
		color: $white;
	}
}

#tlds-search-box:disabled, .sort-btn:disabled {
	opacity: 0.5;
}