// Proxima
@include font-face2(Proxima, '../fonts/proxima-nova/proxima_nova_light-webfont', 100);
@include font-face2(Proxima, '../fonts/proxima-nova/proxima_nova_light-webfont', 100, italic);
@include font-face2(Proxima, '../fonts/proxima-nova/proximanova-reg-webfont', normal);
@include font-face2(Proxima, '../fonts/proxima-nova/proximanova-regitalic-webfont', normal, italic);
@include font-face2(Proxima, '../fonts/proxima-nova/proximanova-bold-webfont', bold);

// Ubuntu
@import url('//fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');

// Socialicons
@include font-face2(Socialicons, '../fonts/Socialicons');
