/*

Checkmarks

A basic list with green checkmarks to the left of each item.

Markup:
<ul class="list-checkmarks">
	<li>Hi</li>
	<li>Awesome job</li>
	<li>You're the best!</li>
</ul>

Styleguide Lists.checkmarks

*/

.list-checkmarks {
	@include list-unset();
	text-align: left;

	li {
		margin-bottom: 1rem;
		padding-left: 1.5em;
		&:before {
			display: block;
			float: left;
			content: '\2713';
			color: #A7DB6F;
			margin-right: 10px;
			margin-left: -1.5em;
		}
	}

	&__heading {
		font-size: 1em;
	}

}
