/*

Half Grid

Text on one-side, photo on the other. Defaults to image on left, use .halfgrid-right to switch it.

Markup:
<section class="section-halfgrid halfgrid-right">
	<div class="media-block grid-block">
		<img src="/assets/images/photo.mika.face.right.jpg" alt="" />
	</div>
	<div class="content-block grid-block">
		<div class="content">
			<div class="content-wrap">
				<h3>Meet Mika. Mika knows WordPress.</h3>
				<p>The WordPress community knows her as Ipstenu, and she's one of WordPress' top contributors. She works in our office alongside coders, system admins, and tech support to help make DreamHost the best WordPress host in the world.</p>
				<a href="/support/" class="btn">Learn More About Our Support</a>
			</div>
		</div>
	</div>
</section>

Styleguide Section.half-grid

*/

.section-halfgrid {
	.media-block {
		img, video {
			display: block;
			min-width: 100%;
			max-width: 100%;
			height: auto;
		}
	}

	.content-wrap {
		background: transparentize($darkBlue, .4);
		background: url(../images/bg.blue.70.png);
		padding: 3.5em;
	}
}

@media (min-width: 830px) {
	.section-halfgrid {
		@include clearfix;
		position: relative;

		.content-block {
			width: 50%;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
		}

		.content {
			display: table;
			height: 100%;
			width: 100%;

			@include media(min-width 830px) {
				@include font-size(14);
			}

			@include media(min-width 1080px) {
				@include font-size(19);
			}
		}

		.content-wrap {
			display: table-cell;
			padding: 2.5em;
			vertical-align: middle;
		}

		&.halfgrid-right {
			.content-block {
				right: auto;
				left: 0;
			}
		}
	}
}
