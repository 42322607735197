/*

Default

A cutiepie little list with nice little blue bullets.

Markup:
<ul class="list-default">
	<li>List item</li>
	<li>List item</li>
</ul>

Styleguide Lists.default

*/

.list-default {
	@include list-unset;
	margin: .5em 0 1em;

	li {
		padding-left: .75em;
		position: relative;

		&:before {
			content: "•";
			color: $lightBlue;
			position: absolute;
			left: 0;
		}
	}
}
