/*

Buttons

Can be used with anchor or button tags. When updating colors, make sure to update _tags.scss as well

Shapes
.btn            - Normal button
.btn--outline   - 1px border button, transparent bg
.btn--text      - Underlined text

Colors
.btn            - Blue
.btn--dark      - Dark blue
.btn--red       - Red
.btn--orange    - Orange
.btn--yellow    - Yellow
.btn--disabled  - Grey

Sizes
.btn            - 16px
.btn--small     - 12px
.btn--large     - 20px

Markup:
<a href="#" class="btn {{modifier_class}}">Text</a>

Styleguide Base.buttons

*/

.btn {
	font-family: $font-body;
	color: $white;
	background: darken($brightblue, 10%);
	border: none;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0,0,0,.15);
	display: inline-block;
	@include font-size(16);
	line-height: 1;
	padding: .8em 1em .7em;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;

	@include transition (all .1s ease-in-out);

	&:hover,
	&:active {
		background: darken($brightblue, 20%);
		color: $white;
	}

	&:active {
		box-shadow: none;
		@include transform(translateY(1px));
	}

	svg {
		vertical-align: middle;

		&:first-child { margin-right: .3em; }
		&:last-child { margin-left: .3em; }

		path { fill: currentColor };
	}
}

// Outline
.btn--outline {
	background: transparent;
	border: 1px solid currentColor;
	box-shadow: none;
	color: $brightblue;

	&:hover,
	&:active {
		background: transparent;
		border-color: currentColor;
		color: darken($brightblue, 15%);
	}

	&:visited { border-color: currentColor; }
}

.btn--text {
	background: transparent;
	border: none;
	box-shadow: none;
	color: $brightblue;
	text-decoration: underline;
	padding: 0;

	&:hover,
	&:active {
		background: transparent;
		color: darken($brightblue, 15%);
	}
}


// Colors
.btn--dark {
	background: $dullblue;
	&:hover, &:active {	background: darken($dullblue, 10%); }

	&.btn--outline, &.btn--text {color: $dullblue; background: transparent; }
	&.btn--outline:hover, &.btn--text:hover {color: darken($dullblue, 15%); background: transparent; }
}

.btn--red {
	background: $red;
	&:hover, &:active {	background: darken($red, 10%); }

	&.btn--outline, &.btn--text {color: $red; background: transparent; }
	&.btn--outline:hover, &.btn--text:hover {color: darken($red, 15%); background: transparent; }
}

.btn--orange {
	background: $orange;
	&:hover, &:active {	background: darken($orange, 10%); }

	&.btn--outline, &.btn--text {color: $orange; background: transparent; }
	&.btn--outline:hover, &.btn--text:hover {color: darken($orange, 15%); background: transparent; }
}

.btn--yellow {
	background: $yellow;
	color: white(14%);
	&:hover, &:active {
		background: darken($yellow, 10%);
		color: white(14%);
	}

	&.btn--outline, &.btn--text {color: $yellow; background: transparent; }
	&.btn--outline:hover, &.btn--text:hover {color: darken($yellow, 15%); background: transparent; }
}

.btn--disabled {
	// Disable Button
	cursor: default;
	text-shadow: none;
	pointer-events: none;

	background: white(95%);
	color: white(32%);
	&:hover, &:active {
		background: white(85%);
		color: white(40%);
	}

	&.btn--outline, &.btn--text {color: white(60%); background: transparent; }
	&.btn--outline:hover, &.btn--text:hover {color: white(40%); background: transparent; }
}

// Sizes
.btn--small { @include font-size(12); }
.btn--large { @include font-size(20); }

// Depreciated
.btn-signup {
	@extend .btn;
	@extend .btn--yellow;
}

.btn-disabled {
	@extend .btn;
	@extend .btn--disabled
}
