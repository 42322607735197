.list-dropdown__wrap {
	position: relative;
}

.list-dropdown {
	@include list-unset;
	position: absolute;
	top: 130%;
	right: 1.5em;
	z-index: 100;

	@media (min-width: 980px) {
		top: 150%;
		right: -1em;
	}

	background: #fff;
	border: 1px solid #E3E3E3;
	border-radius: 5px;
	box-shadow: 0px 2px 4px rgba(0,0,0,.06);
	@include font-size(13);
	padding: .8em 0;

	@include dropdown-animation;

	&:before { // Arrow border
		content: '';
		width: 0;
		height: 0;
		border: 5px solid transparent;
		border-bottom-color: #E3E3E3;
		position: absolute;
		top: -10px;
		right: 1.9em;
	}

	&:after { // Arrow fill
		content: '';
		width: 0;
		height: 0;
		border: 4px solid transparent;
		border-bottom-color: #f7f7f7;
		position: absolute;
		top: -8px;
		right: 2em;
	}

	.icon {
		width: 1.45em;
		height: 1.45em;

		position: absolute;
		top: 50%;
		margin-top: -.725em;
		right: 1em;
	}
}

.list-dropdown--arrow-left {
	&:before { // Arrow border
		left: 1.9em;
		right: auto;
	}

	&:after { // Arrow fill
		left: 2em;
		right: auto;
	}
}

.list-dropdown__link {
	@include button-unset;

	color: #4F5251;
	display: block;
	font-weight: bold;
	line-height: 1;
	padding: .9em 1.8em;
	position: relative;
	text-align: left;
	text-decoration: none;
	white-space: nowrap;
	width: 100%;

	svg path {
		fill: #4F5251;
	}

	&:hover {
		color: $brightblue;

		svg path {
			fill: $brightblue;
		}
	}

	&.is-disabled {
		pointer-events: none;
		opacity: .3;
	}
}
