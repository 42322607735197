#dreampress .section-intro,
#wpbeginner .section-intro {
	background-color: #000;
	@include overlay('/assets/images/logo.wp.silhouette.white.svg', rgba(31,50,68,.98));
	background-size: initial;
	background-position: center 150px;
}

.wordpress-comparison {
	max-width: 850px;
	margin: 0 auto;
	text-align: left;
	td:first-child {
		max-width: 50%;
		font-weight: 600;
	}
}

.dreampress-features {
	.list-bulletpoints {
		svg {
			width: 64px;
		}
		path {
			fill: #fff;
		}
	}
}

.dreampress-pros {
	img {
		width: 42px;
		margin: 2em 0 1em;
	}
	.grid-1_3 {
		padding: 0 1em;
	}
}

.wordpress-comparison-signup {
	vertical-align: top;
	small {
		position: relative;
		left: 7px;
	}
}

#wpbeginner {
	h1 {
		background: #ea6a1b;
		padding: 1em 0;
		font-size: 32px;
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}
	.product-grid .product {
		width: 80%;
		p {
			max-width: 100%;
		}
	}
}
.wpbeginner-wp-logo {
	width: 250px;
	margin-top: 2em;
	opacity: .9;
}

.dreampress-tech-features {
	margin-bottom: 2em;
}

.dreampress-tech-bottom {
	@include clearfix();

	&__left {
		float: left;
		width: 35%;
	}

	&__right {
		float: right;
		width: 50%;
	}

	.list-bulletpoints li {
		width: 100%;
	}
}

.promo-disclaimer {
	background: 0;
	margin: 0;
}

.wpbeginner-disclaimer{
	font-size: 12px;
	max-width: 50%;
	margin: 0 auto;
	margin-top: 2em;
}
