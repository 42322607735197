@mixin font-face2($font-family, $file-path, $weight: normal, $style: normal ) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;

		src: url('#{$file-path}.eot');
		src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
			url('#{$file-path}.svg##{$font-family}') format('svg'),
			url('#{$file-path}.woff') format('woff'),
			url('#{$file-path}.ttf') format('truetype');
	}
}
