/*

Testimonial

Because sometimes other people like our stuff.

Markup:
<div class="testimonial">
	<img src="/assets/images/photo.joe.pardo.jpg" alt="" class="testimonial__photo"/>
	<p>"DreamPress provides my website with the speed, up time, confidence and support to run my podcasting business. My goal of inspiring 1,000's around the world takes a great hosting vendor and DreamHost offers all the right stuff to make it happen."</p>
	<p class="testimonial__credit">—Joe Pardo, Founder of Dreamers Podcast, Consultant, and Organizer of Mid-Atlantic Podcast Conference</p>
</div>

Styleguide Things.testimonial

*/

.testimonial {

	&__credit {
		@include font-size(14);
		font-style: italic;
	}

	&__photo {
		width: 128px;
		border: 10px solid $white;
		border-radius: 50%;
		margin: 0;
	}
}
