/*

Pricing Box

A small, simple, and clear pricing box. It displays 3 in a row.

Usage:
<div class="p-box">
	<div class="p-box__header">
		<span class="p-box__name">Product/plan Name</span>
		<span class="p-box__price">Product/plan Price</span>
	</div>
	<p class="p-box__desc">Product/plan description</p>
	<a href="#" class="btn btn-signup">Call to action</a>
</div>

*/

.p-box {
	width: 100%;
	padding: 1.5em;
	margin-bottom: 5em;
	display: inline-block;
	position: relative;
	text-align: left;
	background: $darkBlue;
	color: $white;

	@media (min-width: 600px) {
		width: 48%;
	}

	@media (min-width: 950px) {
		width: 32.3%;
	}

	&:not(:last-child) {
		margin-left: .5em;
	}

	.p-box__header {
		position: relative;
		width: 100%;
	}

	.p-box__name {
		display: inline-block;
		@include font-size(18);
		font-weight: 700;
	}

	.p-box__price {
		display: inline-block;
		right: 0;
		position: absolute;
		@include font-size(18);
		font-weight: 700;
	}

	.p-box__desc {
		@include font-size(15);
		margin-top: 0;
		color:$offwhite;
	}

	.btn {
		width: 100%;
	}

	.best-price {
		position: absolute;
		width: 100%;
		left: 0;
		padding: 1em;
		top: 9.2em;
		text-align: center;
		font-weight: 700;
		background: $orange;
		color: $white;
	}
}
