/*

Table

A standard nice looking table. Use the additional optional class "responsive" to use Zurb's mobile table library.

Markup:
<table class="table-standard">
	<thead>
		<th>Name</th>
		<th>vCPU</th>
		<th>Memory</th>
	</thead>
	<tbody>
		<tr>
			<td>Subsonic</td>
			<td>1</td>
			<td>1</td>
		</tr>
		<tr>
			<td>Supersonic</td>
			<td>1</td>
			<td>2</td>
		</tr>
		<tr>
			<td>Lightspeed</td>
			<td>2</td>
			<td>4</td>
		</tr>
		<tr>
			<td>Warpspeed</td>
			<td>4</td>
			<td>8</td>
		</tr>
		<tr>
			<td>Hyperspeed</td>
			<td>8</td>
			<td>16</td>
		</tr>
		<tr>
			<td>Ridiculous</td>
			<td>16</td>
			<td>32</td>
		</tr>
	</tbody>
</table>

Styleguide Things.table

*/

.table-standard {
	width: 100%;
	background: $white;
	border-radius: 2px;

	.align-right {
		text-align: right;
	}

	.align-left {
		text-align: left;
	}

	&.small-table {
		width: 75%;
	}

	&.tiny-table {
		width: 60%;
	}

	thead {
		th,
		td {
			@include font-size(18);
			background: $darkBlue;
			color: $white;
			padding: 1em;
		}

		.align-right {
			padding-right: 1.5em;
		}

		.align-left {
			padding-left: 1.5em;
		}
	}

	tbody {
		th,
		td {
			color: $darkBlue;
			padding: 1em;
		}

		.align-right {
			padding-right: 2.5em;
		}

		.align-left {
			padding-left: 2.5em;
		}

		tr:nth-child(even) {
			th,
			td {
				background: $lightgrey;
			}
		}
	}

	&.half-padding {
		thead th, thead td { padding: .35em; }
		tbody th, tbody td { padding: .6em; }
		margin: 2em auto;
	}

	sub {
		font-size: .8em;
		bottom: .07em;
	}
}
