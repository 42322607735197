#dedicated-description {
	@include overlay('../images/hero.profile.01.1660x730.jpg', rgba(31,50,68,.8));
}

.dedicated-details {
	@include outer-container();
}

.dedicated-form {
	max-width: 980px;
	margin: 0 auto;
}

.dedicated-features {
	@media screen and (min-width: 950px) {
		@include span-columns(6);
		@include shift(1);
	}
}

.dedicated-panel-screenshot {
	border: 20px solid #fff;
}

.dedicated-target__box {
	@media screen and (min-width: 700px) {
		@include span-columns(6);
		@include omega(2n);
	}
	padding: 3em;
	background: $lightgrey;
	margin-bottom: 2.35765%; // match neat's grid spacing
	p {
		@include max-width(80%);
		&:last-child {
			margin-bottom: 0;
		}
	}
}

#dedicated-data-center {
	@include overlay('/assets/images/photo.datacenter.jpg', rgba(31,50,68,.8));
	.list-checkmarks {
		max-width: 600px;
		margin: 0 auto;
		text-align: center;
	}
}

#dedicated-comparisons {
	.section-cta {
		background: none;
	}
}

.dedicated-comparisons-table {
	@include table-highlight-column($column: 2);
	-webkit-font-smoothing: antialiased;

	@media screen and (max-width: 975px) {
		box-shadow: none;
		thead th,
		thead td,
		tbody td {
			@include font-size(14);
			padding: 1em .5em;
		}
		tr {
			height: 45px !important; // to override zurb's weird incorrect calculation of the tr height
		}
	}

	&-footnote {
		position: relative;
		display: block;
	}
}

/* -----------------------------------
	## Pricing Configurator
----------------------------------- */

.dedicated-pricing {
	@media screen and (min-width: 950px) {
		@include span-columns(5);
	}
}

.server-config {
	background: #fff;
	border-radius: 2px;
	border: 5px solid rgba(0,0,0,.3);
	position: relative;
	margin-bottom: 1em;

	a {
		text-decoration: none;
		padding: .5rem;
		transition: .3s;
	}

	&__heading {
		-webkit-font-smoothing: antialiased;
		background: $darkBlue;
		color: #fff;
		padding: 1em;
		margin-top: 0;
		@include font-size(16);
	}

	&__options {
		padding: .5em;
		ul {
			padding-left: 0;
			list-style: none;
		}
		li {
			display: inline;
			margin-right: 5px;
			&:last-child {
				margin-right: 0;
			}
		}
		a {
			padding: .25em;
			&.disabled {
				opacity: .3;
				&:hover {
					background: #fff !important;
					color: #333 !important;
					cursor: default;
				}
				&:focus {
					top: 0;
					left: 0;
					box-shadow: none;
				}
			}
			&.active {
				border-bottom: 5px solid $lightBlue;
			}
		}
	}

	.price-circle {
		@media screen and (min-width: 800px) {
			position: absolute;
			top: -20px;
			left: -80px;
			border: 2px solid #fff;
			box-shadow: 2px 2px 5px rgba(0,0,0,.2);
		}
		@media screen and (min-width: 1300px) {
			left: -118px;
		}
		@media screen and (max-width: 800px) {
			// it's not a circle on smaller screens
			width: 100%;
			margin-left: 0;
			border-radius: 0;
			padding: 1em;
			height: auto;
			left: 0;
		}
	}

}
