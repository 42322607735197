@mixin no-dropdown-animation {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

@mixin dropdown-animation {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity .2s, visibility .2s, transform .2s;
    visibility: hidden;

    &.is-active {
		@include no-dropdown-animation;
	}
}
