.domain-search-promos {
    background: transparent-white(.1);
    border-radius: 5px;
    margin-top: 1em;
    padding: 1em;
    text-align: center;
}

.domain-search-promos__list {
    @include mq-min-width(680px) {
        display: table;
        margin-bottom: .5em;
        width: 100%;
    }
}

.domain-search-promos__promo {
    display: inline-block;
    width: 49%;
    margin-bottom: .5em;

    @include mq-min-width(680px) {
        display: table-cell;
        text-align: center;
        width: auto;
    }
}

.domain-search-promos__link {
    text-decoration: none;

    &,
    &:hover {
        color: $white;
    }

    img {
        margin-right: .25em;
    }
}

.domain-search-promos__copy {
    margin: 0;

    a {
        color: $white;
    }
}
