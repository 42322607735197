#company {
	@media (max-width: 640px) {
		h2 {
			font-size: 3rem;
		}
		p {
			font-size: 1.5rem;
		}
	}
}

#company-correspondence {
	p {
		text-align: left;
	}
	.table-standard {
		max-width: 600px;
		margin: 2em auto 0 auto;
		@media (max-width: 600px) {
			margin: 1em auto;
		}
		td {
			padding: 1.75em;
			vertical-align: top;
			@media (max-width: 600px) {
				display: block;
				padding: 0 1.3em;
				margin: 0 auto;
			}
		}
	}
}

.values-icons {
	margin: 65px auto 0;
}

.company-social {
	@include font-size(100);
	@media (max-width: 600px) {
		font-size: 70px;
	}
	height: auto;
	text-transform: none;
	text-align: center;
	display: table;
	margin: 0 auto;
	padding: 0;
	li {
		display: table-cell;
	}

	span {
		display: none;
	}

	a {
		@include light-links;
		text-decoration: none;
	}

	i {
		font-family: 'Socialicons';
		font-style: normal;
	}
}
