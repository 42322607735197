.product-list {
	+ .section__headline {
		margin-top: 6em;
	}

	.section__subheadline + & {
		margin-top: 1em;
	}

	.column {
		margin-top: 4em;
	}

	&:first-child .column {
		margin-top: 0;
	}
}

@media (min-width: 600px) {
	.product-list--col-3 {
		@include build-grid(768px, 3, 220px);
	}

	.product-list--col-2 {
		@include build-grid(690px, 2, 300px);
	}
}

.product-list__icon {
	height: 32px;
	margin-bottom: 15px;
	position: relative;

	svg {
		position: absolute;
		left: 0;
		bottom: 0;
	}
}
