// Page Footer
.page-footer {
	padding: 50px 15px 75px;

	a {
		color: #fff;
		text-decoration: none;

		&:hover {
			color: $brightblue;
		}
	}
}

// Page Footer Nav
.page-footer-nav {
	@include font-size(16);
	@include max-width(925px);
}

.page-footer-nav__column {
	margin: 3em 0;
	float: left;
	width: 50%;

	&:nth-child(2n+1) {
		clear: left;
	}

	@media (min-width: 800px) {
		width: 25%;

		&:nth-child(2n+1) { clear: none; }
		&:nth-child(4n+1) { clear: left; }
	}
}

.page-footer-nav__headline {
	font-size: .75em;
	font-weight: bold;
	margin-bottom: 3px;
}

.page-footer-nav__link-list {
	a {
		display: inline-block;
		padding: 3px 0;
	}
}

// Page Footer Text
.page-footer-text {
	@include font-size(20);
	@include smooth-type;
	margin-top: 50px;
	text-align: center;

	p {
		margin: 0;
	}
}

.page-footer-text--color-default {
	color: #fff;
}

.page-footer-text--color-accent {
	color: $dulllightblue;
}

.page-footer-text--size-large {
	font-size: 1.25em;
}

.page-footer-text--size-small {
	font-size: .75em;
}

.page-footer-text--weight-light {
	font-weight: 300;
}
