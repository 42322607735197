.section-light {
	background: $white;
	color: $dullblue;
	@include unset-smooth-type;

	h1, h2, h3, h4, h5, p {
		a {
			@include dark-links;
		}
	}
}
