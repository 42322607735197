/* Promo page heroe images */
.section-hero--generic:before{ background-image: url(../images/hero.generic.1660x730.jpg); }
.section-hero--02:before{ background-image: url(../images/hero.02.1660x730.jpg); }
.section-hero--03:before{ background-image: url(../images/hero.03.1660x730.jpg); }
.section-hero--04:before{ background-image: url(../images/hero.04.1660x730.jpg); }
.section-hero--05:before{ background-image: url(../images/hero.05.1660x730.jpg); }
.section-hero--privacy:before{ background-image: url(../images/hero.privacy.1660x730.jpg); }
.section-hero--profile-02:before{ background-image: url(../images/hero.profile.02.1660x730.jpg); }
.section-hero--profile-04:before{
	background-image: url(../images/hero.profile.04.1660x730.jpg);
	background-position: 5% top;
}
.section-hero--profile-05:before{ background-image: url(../images/hero.profile.05.1660x730.jpg); }
.section-hero--profile-06:before{
	background-image: url(../images/hero.profile.06.1660x730.jpg);
	background-position: 40% top;
}
.section-hero--profile-08:before {
	background-image: url(../images/hero.profile.08.1660x730.jpg);
	background-position: 30% top;
}
.section-hero--award-01:before{ background-image: url(../images/hero.award.01.1660x730.jpg); }
.section-hero--small-biz-1:before { background-image: url(../images/hero.small.biz.1.1280x730.jpg); }
.section-hero--staff-03:before {
	background-image: url(../images/hero.staff.03.1660x730.jpg);
	background-position: 20% top;
}
.section-hero--staff-04:before {
	background-image: url(../images/hero.staff.04.1660x730.jpg);
	background-position: 35% top;
}
.section-hero--staff-06:before {
	background-image: url(../images/hero.staff.06.1660x730.jpg);
	background-position: 19% top;
}

.section-hero--dreamhost-offices-la:before {
	background-image: url(../images/hero.dreamhost.offices.la.1280x730.jpg);
	background-position: 20% top;
}

@media (min-width: 760px) {
	.section-hero--generic .content-container,
	.section-hero--award-01 .content-container {
		width: 47%;
		margin-left: 50%;
	}
}

// Default Hero Section
.section-hero {
	@extend .section-dark;
	padding: 30px 0;
	position: relative;
	overflow: hidden;
	text-align: center;
	text-shadow: 0 0 5px rgba(0,0,0,0.5);

	&:before {
		background-position: 10% top;
		background-repeat: no-repeat;
		background-size: cover;
		content: '';

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	.hero-disclaimer {
		font-size: 70%;
	}

	.section-wrap {
		position: relative;
		z-index: 2;
	}

	.btn-wrap {
		display: block;
		margin: 20px 0 0;
	}

	.btn {
		margin-top: 1em;
		text-shadow: none;
	}
}

@media (max-width: 760px) {
	.section-hero {
		&:before {
			opacity: .13;
		}
	}
}

@media (min-width: 760px) {
	.section-hero {
		text-align: left;

		.section-wrap {
			@include outer-container;
		}

		.content-container {
			@include span-columns(5.3);
			@include shift(6.5);
		}

		&.light-hero {
			h1,
			p {
				color: #1F3244;
				text-shadow: 0px 0px 10px #fff;
			}
		}
	}
}


// Block Overlay
.section-hero--block-overlay {
	text-align: center;

	.content-container,
	.content-container:last-child {
		// The last-child declaration overrides some dumb span-column specificity

		background: rgba($darkBlue, 0.85);
		padding: 2.3em 2em 2.5em;
		float: none;

		@media (min-width: 760px) {
			/* float: left; <-- used w/right-side graphic */
			/* width: 65%; <-- used w/right-side graphic */
			/* margin-top: 1.5em; <-- used w/right-side graphic */
			width: 75%;
			margin: 1.5em auto 0;
		}
	}
}

.section-hero--block-overlay--dreampress {
	background: rgba(31,50,68,.98);

	&:before {
		background-image: url('/assets/images/logo.wp.silhouette.white.svg');
		opacity: .05;
	}

	.content-container {
		background: rgba(31, 50, 68, 1);
	}

	p {
		max-width: 575px;
		margin: 1em auto;
	}
}

// Centered Overlay
.section-hero--centered-overlay {
	background: $dullblue;
	text-align: center;

	&:before {
		opacity: .15;
	}

	.content-container {
		float: none;
		width: auto;
		margin: 0;
		@include font-size(20);
	}

	.section-hero__subhead {
		font-size: 1.35em;
		line-height: 1.185;
		@include max-width(590px);
	}
}

// Left Overlay
.section-hero--left-overlay {
	background: $dullblue;
	text-align: center;

	@media (min-width: 900px) {
		text-align: left;
	}

	&:before,
	video {
		opacity: .15;
	}

	@media (min-width: 900px) {
		video {
			opacity: 1;
		}
	}

	.content-container {
		float: none;
		width: auto;
		margin: 0;
		@include font-size(20);
	}

	.section-hero__subhead {
		font-size: 1.35em;
		line-height: 1.185;
		@include max-width(590px);
	}
}

// Campaign hero
.campaign-hero {
	position: relative;
}

.campaign-hero__copy {
	position: relative;
	width: 100%;

	@media (min-width: 900px) {
		display: inline-block;
		width: 50%;
	}
}

.campaign-hero__graphic {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	float: right;
	z-index: -1;

	@media (min-width: 900px) {
		display: inline-block;
		left: auto;
		width: auto;
		height: auto;
		z-index: 1;
	}
}

// Photo Only Hero. More of just a photo
.section-hero--photo-only {
	height: 36rem;
}

// Photo Only Hero. More of just a photo
.section-hero--short {
	.section-wrap {
		padding-top: 5em;
		padding-bottom: 3em;
	}
}
