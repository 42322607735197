/*

Testimonial Slider

Because sometimes multiple people like our stuff.

Markup:
<div class="testimonial-slider js-init-slick-slider" data-slick='{"slick-option":"value"}'>
    <div class="list-testimonials__slide-wrap">
        <div class="list-testimonials__slide">
            <div class="list-testimonials__meta">
                <img class="list-testimonials__logo" src="/assets/images/logo.atmail.svg" width="86">
                <img class="list-testimonials__photo" src="//www.gravatar.com/avatar/86662ddb4c613d6061126aa6966b1f17?s=38">
                <br>
                <strong class="list-testimonials__author">Ben Duncan,  Atmail CTO</strong>
            </div>
            <div class="list-testimonials__quote">We successfully migrated 10% of our 30 million email account base to the DreamHost Cloud platform, representing a massive 40% of the company’s revenue. It’s staggering to see the impact this had on our business in terms of TCO and scalability.</div>
        </div>
    </div>
    <div class="list-testimonials__slide-wrap">
        <div class="list-testimonials__slide">
            <div class="list-testimonials__meta">
                <strong class="list-testimonials__author">Chris L</strong>
            </div>
            <div class="list-testimonials__quote">DreamHost has awesome service. Their wonderful attitude and high level of competence convinced me to switch my own sites to DreamHost.</div>
        </div>
    </div>
    <div class="list-testimonials__slide-wrap">
        <div class="list-testimonials__slide">
            <div class="list-testimonials__meta">
                <strong class="list-testimonials__author">Norma P</strong>
            </div>
            <div class="list-testimonials__quote">DreamHost is an affordable very well managed host for websites. The tech staff are excellent—they know their stuff. I highly recommend DreamHost to anyone.</div>
        </div>
    </div>
</div>
<div class="testimonial-slider-nav"></div>

Styleguide Things.testimonial

*/

.testimonial-slider {
    @include max-width(650px);
    margin-bottom: 35px;
}

.list-testimonials__slide {
    display: flex;
    flex-direction: column-reverse;
}

.list-testimonials__meta,
.list-testimonials__quote {
        display: table-cell;
        vertical-align: top;
}


@include mq-min-width(550px) {
    .list-testimonials__slide {
        display: table;
    }

    .list-testimonials__meta,
    .list-testimonials__quote {
            display: table-cell;
            vertical-align: top;
    }
}

.list-testimonials__meta {
    font-size: 13px;
    margin-top: 2em;
    text-align: center;
    white-space: nowrap;

    @include mq-min-width(550px) {
        margin: 0;
        padding-top: 3px;
        padding-right: 40px;
        text-align: right;
    }
}

.testimonial-slider .list-testimonials__photo,
.testimonial-slider .list-testimonials__logo {
    // Have to override .slick-slide img styles
    display: inline;
}

.list-testimonials__photo {
    width: 38px;
    height: 38px;
    border-radius: 19px;
}

.list-testimonials__logo {
    margin-right: 15px;
}

.testimonial-slider-nav {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    .slick-prev,
    .slick-next,
    .slick-dots {
        position: static;
    }

    .slick-prev,
    .slick-next {
        transform: none;
        opacity: 1;
        height: auto;
        width: 30px;

        &:before {
            color: transparentize($dullblue, .8);
            font-size: 50px;
        }
    }

    .slick-prev {
        margin-right: 80px;

        &:before {
            content: '<';
        }
    }

    .slick-next {
        order: 2;
        margin-left: 80px;

        &:before {
            content: '>';
        }
    }

    .slick-dots {
        width: auto;

        li {

            &,
            button {
                width: 10px;
                height: 10px;
            }

            button:before {
                content: '';
                width: 9px;
                height: 9px;
                border-radius: 5px;
                background: white(100%);
                border: 1px solid black(17%);
                opacity: 1;
            }

            &.slick-active button:before {
                background: black(17%);
            }
        }
    }
}
