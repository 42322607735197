/*

Color functions

Create any shade of grey easily.

black(10%) -> #e6e6e6
white(90%) -> #e6e6e6

black(90%) -> #1a1a1a
white(10%) -> #1a1a1a

*/

@function black($percent){
	@return darken(#fff, $percent);
}
@function white($percent){
	@return lighten(#000, $percent);
}
@function transparent-black($opacity){
	@return rgba(0,0,0,$opacity);
}
@function transparent-white($opacity){
	@return rgba(255,255,255,$opacity);
}
