.page-header {
	background: $dullblue;
	padding: 1.5em;
	overflow: hidden;

	&.nav-is-active {
		overflow: visible;
	}

	@include mq-min-width($header-nav--desktop) {
		overflow: visible;
	}

	.button-area {
		display: inline-block;
		line-height: 1;
		padding-top: 3em;

		@include mq-min-width($header-nav--desktop) {
			float: right;
			padding: .2em;
		}
	}

	.btn--outline {
		color: #fff;
		letter-spacing: 1px;
		&:hover {
			background: darken($brightblue, 10%);
			border: 1px solid rgba(0,0,0,0);
		}
	}

}

.page-header--overlay {
	background: transparent;
	position: absolute;
	width: 100%;
	z-index: 100;

	+ .main-content section:first-child {
		padding-top: 4.5em;
	}
}

#logo {
	@include hide-text;
	padding: 0;

	display: inline-block;
	@include size(157px 23px);
	margin-top: .4em;
	margin-right: 1em;
	vertical-align: middle;

	background-image: url(../images/logo.dreamhost.white.svg);
	background-size: 157px 23px;

	span {
		font-size: 0;
	}

}

.btn--toggle-mobile-menu {
	.nav-is-active & {
		margin-bottom: 1.5em;
	}

	@include mq-min-width($header-nav--desktop) {
		display: none;
	}
}

.mobile-nav {
	background: $dullblue;
	clear: both;
	margin: 0 -1.5em;
	padding: 3em 1.5em;
	position: absolute;
	top: -100%;
	left: -100%;
	text-align: center;

	@include dropdown-animation;

	.nav-is-active & {
		position: static;
	}

	@include mq-min-width($header-nav--desktop) {
		@include no-dropdown-animation;
		background: transparent;
		clear: none;
		margin: 0;
		padding: 0;
		text-align: left;

		&, .nav-is-active & {
			display: inline;
			position: static;
		}
	}
}

.gross-ass-social-icons {
	position: absolute;
	display: none;
	top: 1.7em;
	right: 9em;

	@media (min-width: 1000px) {
		display: inline-block;
	}

	.social-icons-list {
		@include list-unset;

		.social-icon {
			display: inline-block;
			width: 2em;
			height: 2em;
		}

		.twitter-icon svg path,
		.facebook-icon svg path,
		.blog-icon svg path {
			stroke: $white;
			transition: all .1s ease-in-out;
		}

		.twitter-icon:hover > svg .bg,
		.facebook-icon:hover > svg .bg,
		.blog-icon:hover > svg .bg {
			fill: $lightBlue;
			stroke: $lightBlue;
		}
	}
}
