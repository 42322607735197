#reachout {
	background: #1f3244 url(../images/bg.blue.png);
}

.reachout-page {
	background: $white;
	border: 1px solid rgba(0,0,0,.1);
	font-size: .75em;
	padding: 3.5em;
	margin: 0 auto;
	max-width: 55em;

	@media (min-width: 55em) {
		border-radius: 3px;
		margin: 7em auto;
	}

	h1 {
		text-align: center;
		margin: 0 0 1.2em;

		& + p {
			margin-top: 0;
		}
	}

	h2 {
		text-align: center;
		margin: 1em 0 1.2em;
	}
}

#list-container {
	background: $lightgrey;
	padding: 15px;
	margin: 40px 0;
}

.reachout-poster {
    display: block;
    margin: 0 auto 40px;
	max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
}

.reachout-list li {
	margin: 0 0 15px;
}

.full-width-btn {
    display: block;
   	margin: 2em auto 7em;
   	padding: 2em 0;
}
