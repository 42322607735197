.pgp-key {
	@include font-size(16);
	margin-top: 2em;
	max-height: 400px;
	overflow: scroll;
	background: #fff;
	padding: 1em;
	color: #333;
	text-align: left;
	background: #fff;
	border: 5px solid #fff;
	border-radius: 5px;
}
