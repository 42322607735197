.modal {
  display: none;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  &-open {
    overflow: hidden;
  }

  &-dialog {
    position: relative;
    width: auto;
    margin: 10px;
  }

  &-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.42857143px;
    .close {margin-top: -2px;}
  }

  &-title {
    margin: 0;
    line-height: 1.42857143;
    font-size: 2em;
  }

  &-content {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    outline: none;
  }

  &-body {
    position: relative;
    padding: 20px;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000000;
    &.fade {
      opacity: 0;
      filter: alpha(opacity=0);
    }
    &.in {
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
  }

  &-footer {
    margin-top: 15px;
    padding: 19px 20px 20px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    .btn + .btn {
      margin-left: 5px;
      margin-bottom: 0;
    }
    .btn-group .btn + .btn {margin-left: -1px;}
    .btn-block + .btn-block {margin-left: 0;}
  }

  button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
  }
  .close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    filter: alpha(opacity=20);
  }


  &.fade .modal-dialog {
     -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }

  &.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }


}


@media (min-width: 768px) {

  .modal {

    &-dialog {
      width: 600px;
      margin: 30px auto;
    }

    &-content {
      -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    &-sm {
      width: 300px;
    }

  }

}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}