// Default FAQ styles
.section-breadcrumb {
	.section-wrap {
		padding: 4em 5% 0;
		text-align: left;
	}

	a {
		margin: 0 .3em;
		text-transform: capitalize;
	}
}

.bc-link:not(:first-child):before {
	content: '\A';
	display: inline-block;
	font-weight: bold;
	margin-right: .3em;
	color: black;
	text-decoration: none;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-color: transparent transparent transparent $lightergrey;
}


.section-faq .section-wrap,
.section-related-faq .section-wrap {
	text-align: left;
}

.section-faq .section-wrap {
	padding: 0 5% 5%;
}

.faq-answer {
	margin-top: 2em;
	font-size: 1.8rem;
	line-height: 1.5em;

	.btn,
	.btn-signup {
		display: inline-block;
		margin-top: 2em;
	}
}

.faq-answer .useful-for {
	@include list-unset;
	margin: 1em 0 3em;

	li {
		width: 100%;
		margin: 0;
		padding: 0 1.5em 1em;
		display: inline-block;
		position: relative;
		vertical-align: top;

		&:before {
			content: '✓';
			position: absolute;
			left: 0;
			top: 0;
			color: $green;
		}

		@media (min-width: 600px) {
			width: 49%;
		}
	}
}

.faq-timestamp {
	color: darken($lightergrey, 10%);
	margin-top: .3em;
}

// Related questions styles
.section-related-faq .section-wrap {
	padding: 3em 5% 8em;
}

.section-related-faq {
	.related-questions {
		@include list-unset;
		margin-top: 1.5em;

		li {
			margin: .3em 0;
		}
	}
}
