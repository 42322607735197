/*

Ad Bubble

Like omg what a sale!

Markup:
<div class="ad-bubble">
	<div class="ad-bubble-wrap">Save<br/> 1 million dollars!</div>
</div>

Styleguide Things.ad-bubble

*/

.ad-bubble {
	background: $peach;
	border-radius: 50%;
	color: white;
	display: table;
	font-size: 3.5rem;
	font-weight: 600;
	line-height: 1;
	padding: 0;
	text-align: center;
	text-shadow: none;
	text-transform: uppercase;

	@include size(150px);
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	margin: -21px -17px 0 0;
	padding: 14px 5px 0 0;

	// Calling this .ad-wrap makes AdBlock remove it LOLOL
	.ad-bubble-wrap {
		display: table-cell;
		vertical-align: middle;
	}
}

@media (max-width: 760px) {
	.ad-bubble {
		font-size: 1.8rem;
		padding: 4px 0 0 0;
		@include size(80px);
		margin: -15px -10px 0 0;
		padding: 20px 3px 0 0;
	}
}
