.vps-pricing {
	.list-table {
		margin: 12em auto 3em;
	}

	@media (min-width: 900px) {
		.pricing {
			@include outer-container;
			margin-bottom: 0;
		}

		.price-circle {
			margin: 0px 0px -40px -2.5em;
		}

		.list-table {
			@include span-columns(3);
			margin-top: 10em;
		}
	}

	.is-hidden {display: none}
}

.vps-billing-choice {
	border-radius: 3px;
	padding: 1em;
	text-align: center;
	display: block;
	background: $white;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .17);
	transition: .3s;
	text-decoration: none;
	margin-bottom: 1em;

	span {
		display: block;
	}

	&__type {
		font-size: 18px;
		font-weight: 500;
		text-transform: capitalize;
	}

	&__price {
		font-weight: bold;
		font-size: 32px;
		font-weight: 600;
		line-height: 50px;
		margin-top: 12px;
	}

	&__details {
		font-style: italic;
	}
}

.list-table li.vps-payment-choice {
	padding: 0;

	&.is-visible {padding: 1.2em;}
	p {margin-top: 0;}
}

.vps-payment-choice-cancel {
	font-size: 14px;
}

#vps-benefits {
	.list-checkmarks {
		@media screen and (min-width: 650px) {
			@include columns(3);
			padding-left: 30px;
		}
	}
	.section-wrap {
		padding: 6em 5%;
	}
	h4 {
		padding-bottom: 3em;
		font-size: 120%;
	}
}

.vps-intro {
	@include overlay('/assets/images/photo.unsplash.road.jpg', rgba(31,50,68,.8))
}

.vps-scalability__box {
	@extend .box;
	margin-bottom: 2em;
	@media screen and (min-width: 600px) {
		@include span-columns(6);
		@include omega(2n);
		margin-bottom: 2.35765%; // neat spacing
	}
	p:last-child {
		margin-bottom: 0;
	}
	background-color: #172a3d;
}

// MySQL VPS Styles
#vps-mysql, .mysql-price {
	a:not(.btn-signup) {
		color: $white;

		&:hover {
			text-decoration: none;
		}
	}

	.link-spacing {
		display: block;
		width: 250px;
		margin: 0 auto 1.5em;
	}
}

.mysql-intro {
	@include overlay('/assets/images/mysql.vps.hero.jpg', rgba(31,50,68,.9))
}

.mysql-panel-screenshot {
	border: 1em solid $white;
	max-width: 900px;
	width: 100%;
	margin: 2em auto;
}

.mysql-manage .list-checkmarks {
	max-width: 800px;
	margin: 0 auto;
}

.mysql-comparison {
	.same-height {
		background: $white;
		min-height: 15.5em;
		padding: 1em;
	}

	.best-plan {
		background: $lightBlue;
	}
}

.mysql-manage {
	h2 {
		margin: 0 auto 1em;
	}

	.caption {
		margin: -2em, auto;
		text-align: right;
		font-size: 14px;
		color: $lightergrey;
		max-width: 900px;
	}
}

.vps-features-container {
	@include outer-container();
}

.vps-feature {
	margin-bottom: 1em;
	padding-bottom: 2em;
	@media screen and (min-width: 600px) {
		@include span-columns(4);
		@include omega(3n);
		padding-bottom: 0;
	}
	img {
		width: 100px;
		margin-bottom: .5em;
	}
	p {
		text-align: left;
	}
}
