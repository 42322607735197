.section-dark {
	background: $darkBlue;
	color: #fff;
	@include smooth-type;

	h1, h2, h3, h4, h5, p {
		a {
			@include light-links;
		}
	}
}
