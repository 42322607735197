
.result-row {
	@media (min-width: 40em) {
		display: table;
		width: 100%;
	}
}

.exact-result {
	.result-row, .actions {
		display: block;
	}
}

.domain-features-container {
	@include outer-container();
}

.domain-feature {
	margin-bottom: 1em;
	@media screen and (min-width: 600px) {
		@include span-columns(4);
		@include omega(3n);
	}

	img {
		width: 100px;
		margin-bottom: .5em;
	}
}

#tlds h2 {
	text-align: center;
}

.tld-list {
	margin: 0;
	@media screen and (min-width: 600px) {
		@include columns(4);
    	list-style-position: inside;
    }
}

.domains-comparisons-table {
	@include table-highlight-column($column: 2);
	-webkit-font-smoothing: antialiased;
	max-width: 700px;
	margin: 0 auto;
	td:first-child {
		width: 33%;
	}
	@media screen and (max-width: 975px) {
		box-shadow: none;
		thead th,
		thead td,
		tbody td {
			@include font-size(14);
			padding: 1em .5em;
		}
		tr {
			height: 45px !important; // to override zurb's weird incorrect calculation of the tr height
		}
	}
}

input[type="text"].domain-input {
	border: 0;
	border-radius: 5px 0 0 5px;
}

/* Start Optimizely Test Page Custom Styles */
.tld-logo-list {
	margin-top: 2em;
	& + p {
		margin-top: 4em;
	}
	li {
		display: inline-block;
		margin: 1em 1.5em 0;
		max-width: 100px;
		@media (min-width: 640px) {
			max-width: 100%;
		}
	}
}

.product-feature-box.domains-feature {
	background: none;
	text-align: left;
	padding: 2em;
	.icon-icann {
		width: 75px;
	}
}

.domains-feature.h1 {
	margin-bottom: 0.5em;
}

.video-com {
	max-width: 400px;
	max-height: 225px;
	margin-bottom: 0;
	&.video-player {
		background-image: url(/assets/images/optim/video.cover.domain.png);
	}

	&.video-player-unloaded:before {
		background: none;
	}
}
/* End Optimizely Test Page Custom Styles */
