.cloud-app-logo-list{
    li {
        display: inline-block;
        &:not(:last-child) {
            margin-right: 3em;
        }
    }
}

.cloud-tab {
	display: none;
	&.selected {
		display: initial;
	}
}

.cloud-toggle {
	h2 {
		color: $darkBlue;
	}
	.section-wrap {
		padding-bottom: 0;
	}
}

.cloud-btns {
	background-color: $lightergrey;
	display: table;
	width: 100%;
	z-index: 1;
	&-toggle {
		display: table-cell;
		width: 50%;
		background-color: lighten($lightgrey, 4);
		color: $darkBlue;
		border: none;
		padding: 1em 0;
	}
	&-toggle.selected {
		background-color: $lightBlue;
		color: $white;
		border-bottom: 1px solid $offwhite;
		border-top: 1px solid $offwhite;
		border-radius: 0;
	}
}

.product-feature-box {
	img {
		margin-bottom: 1em;
	}
}

.btn-tbl {
	background: $lightBlue;
	a {
		color: $white;
		text-decoration: none;
	}
}

.brandname {
	color: $darkBlue;
	font-size: 3.9rem;
	font-weight: 500;
	text-shadow: 0 0 10px #fff;
	font-family: $font-heading;
}

.brandname > span {
	font-size: 15px;
	position: relative;
	top: -15px;
}

#instanceModal {
	.modal-close-text {
		color: $lightBlue;
		text-decoration: none;
		&:hover { color: $dullblue; }
	}
}

.instance-modal-trigger {
	color: $lightBlue;
	text-decoration: none;
	&:hover { color: $dullblue; }
}

#dhc-description {
	// since they're only one line the margins make
	// for a vertically uneven section here without this
	.list-bulletpoints.icon-ballicon>li {
		@media screen and (min-width: 825px) {margin-bottom: 0;}
	}
	.list-default {margin-bottom: 0;}
}

.cloud-comparisons-table {
	@include table-highlight-column(2);
	@include smooth-type;
}

.cloud-comparisons-table td {
	width: 33%;
}

.dhc-prices {
	@include clearfix;
	@media screen and (min-width: 900px) {
		margin: 6em 0 4em;
	}

	.price-block {
		@include price-block(5, 3);
	}

}

#dhc-description {@include overlay('/assets/images/hero.05.1660x730.jpg', rgba(31,50,68,.8));}
#dho-description {@include overlay('/assets/images/hero.01.1660x730.jpg', rgba(31,50,68,.8));}
#dho-testimonial {@include overlay('/assets/images/photo.startupstock.skyline.jpg', rgba(31,50,68,.8));}
#tech-stuff {@include overlay('/assets/images/photo.unsplash.computer.jpg', rgba(31,50,68,.8));}

#hosting-benefits img {
	max-width: 125px;
	margin-top: 3em;
	&:not(:last-child) {
		margin-right: 100px;
	}
}

.dho-prepaid-intro {
	padding: 0 4em;
}

.dho-price-box {
	@extend .box;
	@media screen and (min-width: 650px) {
		float: left;
		width: 33%;
	}
	p {
		margin-bottom: 0;
		font-style: italic;
	}
}
