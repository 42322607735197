/*

Forms

Your standard form. Add .form-submitting with javascript if necessary to add a spinning interaction.

Markup:
<form class="form-standard">
	<div class="input-group">
		<label for="firstname">First Name</label>
		<input type="text" name="firstname" class="firstname">
	</div>
	<div class="input-group">
		<label for="lastname">Last Name</label>
		<input type="text" name="lastname" class="lastname">
	</div>
	<div class="input-group">
		<label for="email">Email Address</label>
		<input type="email" name="email" class="email">
	</div>
	<div class="input-group">
		<label for="password">Password</label>
		<input type="password" name="password" class="password" autocomplete="off">
	</div>
	<div class="input-group">
		<label class="label-checkradio"><input type="checkbox" name="agree" class="agree">I agree to things.</label>
	</div>
	<footer class="form-footer">
		<button type="submit" class="btn-signup">Let's do this!</button>
	</footer>
</form>

<form class="form-standard">
	<div class="input-group input-group--inline-btn">
		<input type="text" placeholder="Find your domain name">
		<div class="input-group__btn">
			<button type="submit" value="Search" class="btn">Search</button>
		</div>
	</div>
</form>

Styleguide Forms.stanard-form

*/
.form-standard {
	text-align: left;

	&.centered {
		text-align: center;
	}

	// Input Groups
	.input-group {
		+ .input-group { margin-top: 2em; }

		&.error {
			input,
			textarea {
				box-shadow: inset 0 0 1px 2px $red;
			}

			select {
				// There's a white bg behind the border we sample the color on a dark background
				border-color: #86333B;
			}

			.label-checkradio {
				color: $red;
			}
		}

		&.results {
			margin-top: 1em;
			text-align: center;

			> div {
				background: $teal;
				border: 1px solid $teal;
				border-radius: 3px;
				display: inline-block;
				color: $white;
				margin: 0 auto;
				padding: 1em;
			}

			&.success > div {
				background: $green;
				border-color: $green;
			}

			&.error > div {
				background: $red;
				border-color: $red;
			}

			p:first-child {
				margin: 0;
			}
		}
	}

	.form-footer {
		margin-top: 2em;
		text-align: center;
	}

	// Submitting
	&.form-submitting {
		.btn,
		.btn-signup {
			position: relative;

			&:after {
				content: '';
				display: block;
				@include spinner(25px 25px);
				position: absolute;
				top: 50%;
				left: 110%;
				margin-top: -12px;
			}
		}
	}

	// Label
	label {
		display: block;
		margin-bottom: .5em;

		&.label-checkradio {
			cursor: pointer;
		}
	}

	// Inputs
	#{$all-text-inputs},
	select,
	textarea {
		background: $white;
		border: 1px solid lighten($lightergrey, 15%);
		border-radius: 5px;
		font-size: 1em;
		padding: .8em 1em .7em;

		@include transition(box-shadow .2s ease-in-out);

		+ .btn,
		+ .btn-signup {
			margin-left: 1em;
			margin-top: -5px;
		}

		&:focus {
			box-shadow: 0 1px 3px rgba(0,0,0,.3);
			outline: none;
		}
	}

	input,
	select,
	textarea {
		width: 100%;
	}

	input {
		color: $darkBlue;

		@include placeholder {
			color: desaturate( lighten($darkBlue, 60%), 100%);
		}
	}

	input[type=checkbox],
	input[type=radio] {
		width: auto;
		margin-right: .5em;
	}

	select {
		color: $darkBlue;
		line-height: 2.9em;
		height: 2.9em;
		border: .05em solid #D6D6D6;
		// select boxes (in Chrome/Safari) can't have opaque borders. There's an automatic white fill behind them.

		.section-dark & {
			border-color: #384B59;
		}

		&:focus {
			border-color: #307D9D;
		}

		&:-moz-focusring {
			// Remove weird outline from Firefox
			color: transparent;
			text-shadow: 0 0 0 #000;
		}
	}

	textarea {
		color: $darkBlue;
	}
}


// Inline-btn
// See markup at the top for how to use
// Works on both left or right side of the input
.input-group--inline-btn {
	display: table;

	.btn {
		font-size: 1em;
		padding-bottom: .85em; // Why????
	}

	input,
	.input-group__btn {
		display: table-cell;
		vertical-align: middle;
	}

	input { width: 100%; }
	.input-group__btn {	width: 1%; }

	input:first-child,
	.input-group__btn:first-child .btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.input-group__btn + input,
	input + .input-group__btn .btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	input:focus + .input-group__btn .btn {
		box-shadow: 0 1px 3px rgba(0,0,0,.3);
	}
}

// Sizes
.input--size-auto {
	width: auto;
}
