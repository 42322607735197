@mixin table-highlight-column($column) {
  td {
    border-bottom: 1px solid $lightgrey;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  tbody tr:nth-child(even) td {
    background: none; // remove highlight from table-standard
  }
  tbody tr td:nth-child(#{$column}),
  thead th:nth-child(#{$column}) {
    background: $lightBlue;
    color: #fff;
    border-bottom: 1px solid darken($lightBlue, 5%);
  }
}
