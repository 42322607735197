/*

Bulletpoints

For some reason they're called list-bulletpoints, these are floated lists with icons.

For three columns, use the class list-bulletpoints. Otherwise you can use your own class and include the list-bulletpoints() mixin with the number of columns as paramaters, e.g .your-four-column-list {@include list-bulletpoints(4)}.

See _list-bulletpoints.scss for all icon possibilities.

Markup:
		<ul class="list-bulletpoints icon-ballicon">
			<li class="icon-websites">
				<h3>Hand-rolled Control Panel</h3>
				<p>We've spent 12 years building this thing with blood, sweat, and a lot of tacos.</p>
			</li>
			<li class="icon-transfer">
				<h3>1-Click Installer</h3>
				<p>Dead-simple installation of web apps like WordPress, Joomla! and more!</p>
			</li>
			<li class="icon-bandwidth">
				<h3>Solid State Drives (SSDs)</h3>
				<p>All of our hosting plans use solid state drives. With SSDs, your website, caching and database queries are faster.</p>
			</li>
			<li class="icon-users-guy">
				<h3>Real-life Human Beings</h3>
				<p>Tech veterans, geeks, and nerds are all standing by to optimize your DreamHost experience.</p>
			</li>
			<li class="icon-protect">
				<h3>SSL Certificates</h3>
				<p>Available as an add-on, your very own SSL certificate will keep payments and your customer data secure.</p>
			</li>
			<li class="icon-award">
				<h3>Award Winning Support</h3>
				<p>Our in-house support and service team are here for you 24/7, 365.</p>
			</li>
			<li class="icon-opt-1">
				<h3>Automated Malware Scans</h3>
				<p>Cutting-edge malware monitoring and remediation tools.</p>
			</li>
			<li class="icon-opt-3">
				<h3>Cloudflare Integration</h3>
				<p>Cache content and filter malicious traffic before it hits your server.</p>
			</li>
			<li class="icon-cert">
				<h3>100% Uptime Guarantee</h3>
				<p>We'll credit you a day's service for every hour of interrupted service. Seriously.</p>
			</li>
		</ul>

Styleguide Lists.bullets

*/

@mixin list-bulletpoints($columns) {
	@include list-unset;
	@extend .cf;
	margin: 3em 0 1em;

	> li {
		@include font-size(23);
		font-weight: bold;
		margin: 1em 8%;
		padding: 0 .7em 0 1em;
		position: relative;
		text-align: left;
		margin-left: 0;

		@if $columns == 2 {
			@media (min-width: 500px) {
				margin-left: 0;
				@include span-columns(6);
				@include omega(2n);
			}
		}

		@if $columns == 3 {
			@media (min-width: 800px) and (max-width: 1199px) {
				@include span-columns(6);
				@include omega(2n);
			}
			@media (min-width: 1200px) {
				@include span-columns(4);
				@include omega(3n);
			}
		}

		@if $columns == 4 {
			@media (min-width: 800px) and (max-width: 1199px) {
				@include span-columns(6);
				@include omega(2n);
			}
			@media (min-width: 1200px) {
				@include span-columns(3);
				@include omega(4n);
			}

		}

		&:before {
			background: $lightBlue;
			border-radius: 4px;
			content: '';
			display: block;
			@include size(8px);

			position: absolute;
			top: .5em;
			left: 0em;
		}

		.section-dark & {
			color: $white;
		}
	}

	&.icon-ballicon > li {
		padding-left: 3.5em;
		padding-top: .75em;
		margin-bottom: 2em;

		&:before {
			@include size(65px);
			background-size: 65px 65px;
			background-color: transparent;
			top: 0;
		}

		&.icon-console:before {
			background-image: url(../images/icon.console.svg);
		}

		&.icon-email:before {
			background-image: url(../images/icon.email.svg);
		}

		&.icon-storage:before {
			background-image: url(../images/icon.storage.svg);
		}

		&.icon-transfer:before {
			background-image: url(../images/icon.transfer.svg);
		}

		&.icon-domains:before {
			background-image: url(../images/icon.domains.svg);
		}

		&.icon-users-guy:before {
			background-image: url(../images/icon.users.guy.svg);
		}

		&.icon-support-2:before {
			background-image: url(../images/icon.support.2.svg);
		}

		&.icon-websites:before {
			background-image: url(../images/icon.websites.svg);
		}

		&.icon-blog:before {
			background-image: url(../images/icon.blog.svg);
		}

		&.icon-shop:before {
			background-image: url(../images/icon.shop.svg);
		}

		&.icon-folio:before {
			background-image: url(../images/icon.folio.svg);
		}

		&.icon-client-gal:before {
			background-image: url(../images/icon.client.gal.svg);
		}

		&.icon-sell:before {
			background-image: url(../images/icon.sell.svg);
		}

		&.icon-bandwidth:before {
			background-image: url(../images/icon.bandwidth.svg);
		}

		&.icon-ram:before {
			background-image: url(../images/icon.ram.svg);
		}

		&.icon-opt-1:before {
			background-image: url(../images/icon.opt.1.svg);
		}

		&.icon-opt-2:before {
			background-image: url(../images/icon.opt.2.svg);
		}

		&.icon-opt-3:before {
			background-image: url(../images/icon.opt.3.svg);
		}

		&.icon-powered:before {
			background-image: url(../images/icon.powered.svg);
		}

		&.icon-traffic:before {
			background-image: url(../images/icon.traffic.svg);
		}

		&.icon-apps-1:before {
			background-image: url(../images/icon.apps.1.svg);
		}

		&.icon-message:before {
			background-image: url(../images/icon.message.svg);
		}

		&.icon-backup:before {
			background-image: url(../images/icon.backup.svg);
		}

		&.icon-open:before {
			background-image: url(../images/icon.open.svg);
		}

		&.icon-compat-1:before {
			background-image: url(../images/icon.compat.1.svg);
		}

		&.icon-protect:before {
			background-image: url(../images/icon.protect.svg);
		}

		&.icon-award:before {
			background-image: url(../images/icon.award.svg);
		}

		&.icon-pricing:before {
			background-image: url(../images/icon.pricing.svg);
		}

		&.icon-auto:before {
			background-image: url(../images/icon.auto.svg);
		}

		&.icon-fast:before {
			background-image: url(../images/icon.fast.svg);
		}

		&.icon-cloud-1:before {
			background-image: url(../images/icon.cloud.1.svg);
		}

		&.icon-cert:before {
			background-image: url(../images/icon.cert.svg);
		}

		&.icon-mag:before {
			background-image: url(../images/icon.mag.svg);
		}

		&.icon-wp:before {
			background-image: url(../images/icon.wp.svg);
		}

		&.icon-ceph:before {
			background-image: url(../images/icon.ceph.svg);
		}

		&.icon-green-e:before {
			background-image: url(../images/icon.green-e.svg);
		}
	}

	&.icon-ballicon.icon-top > li {
		padding: 0 1.5em;

		&:before {
			@include size(150px);
			background-size: 150px 150px;
			position: static;
			margin: 0px auto 1.2em;
		}

		h3, p {
			@include max-width(300px);
		}
	}

	h3 {
		font-size: 1em;
		margin: 0px 0px .4em;
	}

	p,
	li li {
		font-size: .7em;
		font-weight: normal;
		margin: 0;

		.section-dark & {
			color: $offwhite;
		}
	}

	.section-dark & a {
		color: $white;

		&:hover {
			color: $teal;
		}

		&.btn:hover {
			background: darken($lightBlue, 5%);
			color: #fff;
		}

	}

 	h3 a {
		color: $darkBlue;
	}
}

.list-bulletpoints {@include list-bulletpoints($columns: 3)}
.list-bulletpoints-two-column {@include list-bulletpoints($columns: 2)}
.list-bulletpoints-four-column {@include list-bulletpoints($columns: 4)}
