/*

DH Logo Loading Spinner

Fancy spinny spinner using DH logo (or anything else you'd like spinny)!

Markup:
<div class="dhlogo-spinner">
	<img src="{{Spinny image url}}" />
	<div class="loading-text">Searching</div><!-- If you want loading text -->
</div>

Styleguide Things.dhlogo-spinner

*/

.dhlogo-spinner {
	text-align: center;
	margin: 0 auto;
	margin-top: 3em;
	img {
		animation: logo-spinner ease 1s infinite;
		padding: 1em;
		background-color: lighten($darkBlue, 5);
		border-radius: 50%;
		margin-bottom: 0.5em;
	}
}

@keyframes logo-spinner {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@-o-keyframes logo-spinner {
	from {
		-o-transform: rotate(0deg);
	}
	to {
		-o-transform: rotate(360deg);
	}
}

@-ms-keyframes logo-spinner {
	from {
		-ms-transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
	}
}

@-webkit-keyframes logo-spinner {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes logo-spinner {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}
