#shared-description.section-intro {
	@include overlay('../images/hero.profile.03.1660x730.jpg', rgba(31,50,68,.8));
}

#shared-trust {
	.section-cta {background: none;}
}

#shared-tech .list-checkmarks {
	@media screen and (min-width: 650px) {
		@include columns(3)
	}
}

#shared-tech li a {
	color: #dddddd;
}

.domains-disclaimer {
	background-color: #eee;
	color: $darkBlue;
	display: inline-block;
	font-size: .7em;
	text-align: center;
	margin-top: 60px;
	padding: 17px 30px;
	max-width: 600px;
	border-radius: 40px;
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
}

.all-plans-h2 {
	margin-top: 3em;
}

.p-extras {

	.p-li {
		@include font-size(18);
		font-weight: normal;

		.sub {
			display: block;
			@include font-size(15);
			color: lighten($grey, 10%);
		}
	}

	&.list-bulletpoints-two-column.icon-ballicon > li {
		padding-left: 5.5em;
	}
}
