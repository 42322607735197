/*

Intro

The start to a product page.

To add a background include the overlay mixin in some type of
#identifier.section-intro {
	@include overlay('../images/hero.profile.03.1660x730.jpg', rgba(31,50,68,.8));
}

Markup:
<section id="shared-description" class="section-intro">
	<div class="section-wrap">
		<h1 class="u-maxwidth-700">Get your website online in minutes!</h1>
		<p class="u-maxwidth-700">Our shared website hosting plan is the quickest and most affordable way to get your website online. It's perfect for blogs, portfolios, online stores and even complex database-driven sites. and best of all, with solid state drives (SSDs), your website will load 200% faster.</p>
		<div class="section-cta">
			<h2 class="u-maxwidth-700">Get the best web hosting for $8.95/mo<br><small class="text-sale">+ 1 free domain name <em>forever</em>!</small></h2>
			<a href="//signup.dreamhost.com/shared/email/" class="btn-signup">Sign up for Web Hosting Today</a>
		</div>
	</div>
</section>

Styleguide Section.intro

*/

.section-intro {
	@extend .section-centered;
	@extend .section-dark;

	h1 {
		@extend h2.spacing;
		font-weight: 100;
		font-size: 4.2rem;
	}

	&__lead-in {
		font-size: 1em;
		font-weight: bold !important; // .section-dark heading font-weights is too specific
	}

	.section-cta {
		max-width: 700px;
		margin: 2em auto 0;
		padding: 2em;
		h2 {margin-bottom: 1em}
	}

	.intro-subtext-link {
		display: block;
		color: lighten($grey, 40%);
		margin-top: 2em;
		font-size: 1.4rem;

		&:hover {
			text-decoration: none;
		}
	}

}
