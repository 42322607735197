#dhc-campaign {
	.dhc-campaign__h2 {
		font-weight: 600;
	}

	p.spacing {
		margin-bottom: 2rem;
	}

	.btn-signup {
		padding-left: 5rem;
		padding-right: 5rem;
	}

	.section-hero {
		padding: 0;

		@media (min-width: 900px) {
			padding: 30px 0;
		}
	}
}

.spiceworks-logo {
	width: 280px;
	margin-bottom: 4rem;
}

.dhc-laptop {
	position: absolute;
	width: 100%;
	height: 100%;
}

.dhc-video-container {
	position: relative;
	width: 900px;
	height: 100%;
	margin-left: calc(50% - 450px);
}

.dhc-mov-launch-server {
	position: absolute;
	left: 0;
	height: 100%;

	@media (min-width: 870px) {
		width: 100%;
		height: auto;
	}
}

@media (min-width: 900px) {
	.dhc-laptop {
		top: 4rem;
		width: 706px;
		height: 420px;
		background: url('../images/dhc.laptop.svg') top left;
		z-index: 1;
	}

	.dhc-mov-launch-server {
		position: relative;
		width: 590px;
		height: auto;
		top: 3rem;
		left: 4rem;
		background: url('../images/dhc.panel.launch.server.thumb.png') top left no-repeat;
		background-size: 100% auto;
	}

	.dhc-video-container {
		position: relative;
		top: 1.3rem;
		left: 2.1rem;
		width: 629px;
		height: 365px;
		margin-left: 0;
		overflow: hidden;
	}
}

.dhc-partners {
	background: $white;

	ul {
		@include list-unset;
	}

	li {
		display: inline-block;
		margin: 0 auto 5rem;
		width: 100%;

		@media (min-width: 600px) {
			margin: 3rem 2rem 6rem;
			width: 40%;
		}

		@media (min-width: 950px) {
			margin: 2rem 4rem;
			width: auto;
		}

		@media (min-width: 1100px) {
			margin: 2rem 6rem;
		}
	}
}

.dhc-partners img {
	position: relative;
	left: auto;
	vertical-align: middle;

	@media (min-width: 800px) {
		left: -3rem;
	}
}

.dhc-pcp-logo {
	height: 20rem;
}

.dhc-centos-logo {
	height: 7rem;
}

.dhc-fedora-logo {
	height: 5rem;
}

.dhc-debian-logo {
	height: 10rem;
}

.dhc-features,
.dhc-pricing-section,
.dhc-community {
	background: lighten($offwhite, 7%);
}

.dhc-features .section-wrap {
	padding: 8em 5%;

	&:nth-child(even) {
		background: $subtleblue;
	}

	@media (min-width: 1016px) {
		padding: 8em 5% 2em;

		&:nth-child(even) {
			background: lighten($offwhite, 7%);
		}
	}
}

.dhc-features .section-offscreen__copy {
	max-width: 550px;
}

.dhc-features.control-panel-benefits .section-offscreen__graphic {
	width: 360px;
	right: 0;
	margin-bottom: 5rem;
	margin-top: 5rem;
}

.dhc-features .section-offscreen__copy + .section-offscreen__graphic {
	left: 0;
}

.dhc-features .spacing,
.dhc-pricing-table {
	padding: 5rem 0 10rem;
	margin: 5rem 0;
}

.dhc-community {
	text-align: left;

	ul {
		@include list-unset;
		margin: 0 auto;
	}

	li {
		display: block;
		margin: 5rem 4rem 4rem 2rem;
	}
}

.dhc-btn-container {
	width: 100%;
	text-align: center;
}

.dhc-community__copy {
	max-width: 600px;
}

@media (min-width: 900px) {
	.dhc-community {
		text-align: center;

		ul {
			margin-left: 0;
		}

		li {
			display: inline-block;
			vertical-align: top;
			text-align: left;
		}
	}

	p.dhc-community__copy {
		max-width: 135px;
	}
}
