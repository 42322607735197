/*

Video Player

For videos!

Markup:
<section class="section-dark section-centered">
	<div class="section-wrap">
		<h2 class="u-maxwidth-700">How's it work?<br/>What's under the hood?</h2>
		<p class="u-maxwidth-700">Text and icons can only say so much. We get it. This is why we've taken the time to put together a series of videos that better explain how DreamObjects works, and how you can get the most out of it.</p>
		<div class="video-player-wrap">
			<div class="video-player video-player-unloaded" data-video-src="//www.youtube.com/embed/kajEZnX0GO8?rel=0&autoplay=1"></div>
		</div>
	</div>
</section>

Styleguide Things.video-player

*/



.video-player-wrap {
	margin: 2em auto 10em;
	max-width: 500px;
	padding-bottom: 5%;
	position: relative;
	// keep video player at 4:3 aspect ratio
}

.video-player {
	@include outer-glow;
	background-image: url(../images/video.cover.company.jpg);
	background-size: cover;
	position: relative;
	margin: 2em auto;

	width: 300px;
	height: 180px;
	@media (min-width: 640px) {
		width: 500px;
		height: 300px;
	}
	@media (min-width: 1100px) {
		width: 1000px;
		height: 600px;
	}

	&.video-player-unloaded {
		cursor: pointer;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			right: 50%;
			margin: -102px -102px 0 0;
			background: url(../images/video.play.png);
			@include size(204px);

			@media (max-width: 640px) {
				height: 102px;
				width: 102px;
				margin: -51px -51px 0 0;
				background-size: 102px 102px;
				background-repeat: no-repeat;
			}
		}
	}
}

.video-player--dho-image {
	background-image: url(../images/video.cover.dho.jpg);
}
