/*

Product Feature Box

Use the class same-height to match all sibling boxes to the tallest one.
Use the additional class product-feature-box__wide for a full-width version. Make sure to use a clearfix.

Markup:
<div class="clearfix">
	<div class="product-feature-box same-height">
		<h3>Rock Solid Security</h3>
		<img src="/assets/images/budicon/lock.svg" />
		<p>All DreamCompute servers use SSH key authentication by default to protect against password hacking. You'll also get your own physical private network with isolated Layer 2 networks and be able to create server-level firewalls using OpenStack's security groups.</p>
	</div>
	<div class="product-feature-box same-height">
		<h3>Redundant Storage</h3>
		<img src="/assets/images/budicon/storage.svg" />
		<p>Rest easy as every piece of data stored in the Ceph storage cluster is replicated a total of three times. If something should fail, the Ceph cluster springs into action to automatically self-heal, ensuring that the proper number of replicas exist.</p>
	</div>
</div>
<div class="product-feature-box product-feature-box__wide">
	<h3>And 24/7 Expert Support</h3>
	<img src="/assets/images/budicon/chat.svg" />
	<p>DreamHost's best cloud minds are available 24/7 via live chat, email and Twitter. In addition, the <a href="http://wiki.dreamhost.com/Category:DreamCompute" target="_blank">DreamCompute Wiki</a> is full of helpful articles written to make your DreamCompute experience a dream.</p>
</div>

Styleguide Things.product-box

*/

.product-feature-box {
	@extend .box;
	margin: 1em 0 2em;
	text-align: center;

	@media screen and (min-width: 650px) {
		width: 50%;
		float: left;
	}
	img {
		width: 50px;
		opacity: .8;
		margin-top: 2em;
	}
	&__wide {
		background: none;
		width: 100%;
		float: none;
		padding: 0;
		&:nth-child(2) {background: none;}
	}
}
