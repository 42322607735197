/* Color Variables

This is documented at
dropbox/~Resources/UI Patterns/Colors.sketch

Please update when these variables change

*/

$white: darken(#fff, 2%);
$lightgrey: darken(#fff, 7%);
$offwhite: darken(#fff, 14%);
$lightergrey: darken(#fff, 36%);
$grey: #5d5e61;
$bluegrey: #505c6a;

// Brand Colors
$darkBlue: #1f3244;
$lightBlue: #117fb2;
$yellow: #ffba00;
$teal: #009ea4;
$peach: #f6666a;
$green: #00a46c;

// Redesign Colors
$dullblue: #2F323B; // WCAG: Passes with White
$brightblue: #35AEE6; // WCAG: Passes with $dullblue
$dulllightblue: #91A2B6;
$subtleblue: #edf4f7;
$red: #E65935;
$orange: #FF8000;
