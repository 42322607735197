.ribbon-popular {
	background: $peach;
	color: $white;
	font-size: .9em;
	font-weight: 600;
	padding: 8px 17px;
	text-align: center;
	text-transform: uppercase;
	white-space: nowrap;

	// Cool CSS Centering
	position: absolute;
	top: 0;
	left: 50%;
	@include transform( translate(-50%, -50%) );

	// This doesn't work in IE8. SUXXXXX
	.no-csstransforms & {
		left: 0;
		right: 0;
		margin-top: -20px;
		// Gross work around but it doesn't look terrible.
		// This is the only way to keep the ribbon to not look crazy and still be variable width and height.
	}
}
