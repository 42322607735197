/*

Announcement Bar

For announcements.

Markup:
<section class="section-announcement-bar">
	<div class="section-wrap">
		<div class="announcement-bar-image">
			<img src="/assets/images/robot.presents.right.svg" width="85" height="85" />
		</div>
		<div class="announcement-bar-content">
			<h2>Includes 1 Free Domain Name for the Life of Your Hosting Plan</h2>
			<p>Most companies offer a free domain for a year. We give you 1 for as long as you pay your hosting bill.</p>
		</div>
	</div>
</section>

Styleguide Things.announcements

*/

.section-announcement-bar {
	background: $white;
	display: block;
	text-decoration: none;
	padding: 1em 0;

	.section-wrap {
		padding-top: 1em;
		padding-bottom: 1em;
	}
}

.announcement-bar-image img {
	display: block;
	margin: 0 auto;
}

.announcement-bar-content {
	padding: 0 1.3em;
	text-align: center;

	h2 {
		@include font-size(24);
		font-weight: bold;
		margin-top: 0;
	}

	p {
		max-width: 700px;
	}

	p:last-of-type {
		margin-bottom: 0;
	}
}

@media (min-width: 600px) {
	.section-announcement-bar {
		.section-wrap {
			display: table;
			padding-top: 0.9em;
			padding-bottom: 0.9em;
		}
	}

	.announcement-bar-image,
	.announcement-bar-content {
		display: table-cell;
		vertical-align: middle;
	}
}
