.container {
	@include clearfix();
	max-width: 1000px;
	margin: 0 auto;
}

@media screen and (min-width: 650px) {
	.grid-1_2 {@include span-columns(6);}
	.grid-1_3 {@include span-columns(4);}
	.grid-2_3 {@include span-columns(8);}
}
