// ---------------------------------------------------------------------------------------------------------------------
//
//  Stolen and Modified from https://github.com/paranoida/sass-mediaqueries
//
//  Example:
//	div {
//		display: block;
//
//		@include min-width(800px) {
//			diplay: none;
//		}
//	}
//
//  Compiles to:
//	div { display: block; }
//	@media screen and (min-width: 800px) {
//		div { diplay: none; }
//	}
//
// ---------------------------------------------------------------------------------------------------------------------

@mixin mq-min-max-width($min-width, $max-width) {
	@media screen and (min-width: $min-width) and (max-width: $max-width) {
		@content;
	}
}

@mixin mq-max-width($max-width) {
	@media screen and (max-width: $max-width) {
		@content;
	}
}

@mixin mq-min-width($min-width) {
	@media screen and (min-width: $min-width) {
		@content;
	}
}

@mixin mq-min-max-height($min-height, $max-height) {
	@media screen and (min-height: $min-height) and (max-height: $max-height) {
		@content;
	}
}

@mixin mq-max-height($max-height) {
	@media screen and (max-height: $max-height) {
		@content;
	}
}

@mixin mq-min-height($min-height) {
	@media screen and (min-height: $min-height) {
		@content;
	}
}


// Based on bourbon hidpi-media-queries file (https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_hidpi-media-query.scss) HiDPI mixin
@mixin mq-dpi($dpi: 2) {
	@media only screen and (-webkit-min-device-pixel-ratio: $dpi),
		only screen and (min--moz-device-pixel-ratio: $dpi),
		only screen and (-o-min-device-pixel-ratio: #{$dpi}/1),
		only screen and (min-resolution: #{round($dpi*96)}dpi),
		only screen and (min-resolution: #{$dpi}dppx) {
		@content;
	}
}
